import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from "react-icons/fa"; // Import icons
import { CSRContainer, CSRRow, SingleCSR } from "../../CSR/styles";
import "../Branch/style.css";
import axios from "axios";

const DealerBranch = () => {
      const [loading, setLoading] = useState(false);
      const [locations, setLocations] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dealer_locations`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setLocations(response.data.locations);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <div className="sectionMargin marginBottom">
                  <CSRContainer>
                        <CSRRow>
                              <h2 className="dealer-branch-h2">
                                    Our Dealers Location
                              </h2>
                              {locations?.map((data, i) => (
                                    <Col
                                          lg="4"
                                          key={i}
                                          className="branch-dealer-cards"
                                    >
                                          <SingleCSR className="branch-dealer-cards-padding">
                                                <h4>{data.name}</h4>
                                                <p>
                                                      <FaPhoneAlt className="branch-icons" />{" "}
                                                      {data.phone_number}
                                                </p>
                                                <p>
                                                      <FaMapMarkerAlt className="branch-icons" />{" "}
                                                      {data.address}
                                                </p>
                                                <p>
                                                      <FaEnvelope className="branch-icons" />{" "}
                                                      {data.email}
                                                </p>
                                          </SingleCSR>
                                    </Col>
                              ))}
                        </CSRRow>
                  </CSRContainer>
            </div>
      );
};

export default DealerBranch;
