import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
`;
export const BarlowRegular = css`
	font-family: ${(props) => props.theme.barlowFont};
	font-family: "Barlow", sans-serif;
`;
export const RobotoRegular = css`
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
`;
export const SoraRegular = css`
	font-family: "Sora", sans-serif;
`;
