import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
import { PolicyBox, PolicyImage, PolicyTitle } from "../styles";

const PolicySwiper = () => {
      return (
            <>
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={false}
                        autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                        }}
                        loop={true}
                        breakpoints={{
                              640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                              },
                              1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                              },
                        }}
                        modules={[Autoplay, Pagination, EffectFade]}
                        className="mySwiper mt-5"
                  >
                        <SwiperSlide>
                              <div className="d-flex justify-content-center align-items-center">
                                    <Link to="/terms-and-condition">
                                          <PolicyBox>
                                                <PolicyImage>
                                                      <img
                                                            src="/policy/terms-and-conditions.png"
                                                            alt=""
                                                      />
                                                </PolicyImage>
                                                <PolicyTitle>
                                                      Terms and Condition
                                                </PolicyTitle>
                                          </PolicyBox>
                                    </Link>
                              </div>
                        </SwiperSlide>
                        <SwiperSlide>
                              <div className="d-flex justify-content-center align-items-center">
                                    <Link to="/privacy-policy">
                                          <PolicyBox>
                                                <PolicyImage>
                                                      <img
                                                            src="/policy/privacy-policy.png"
                                                            alt=""
                                                      />
                                                </PolicyImage>
                                                <PolicyTitle>
                                                      Privacy Policy{" "}
                                                </PolicyTitle>
                                          </PolicyBox>
                                    </Link>
                              </div>
                        </SwiperSlide>
                        <SwiperSlide>
                              <div className="d-flex justify-content-center align-items-center">
                                    <Link to="/dealer-policy">
                                          <PolicyBox>
                                                <PolicyImage>
                                                      <img
                                                            src="/policy/dealer-policy.png"
                                                            alt=""
                                                      />
                                                </PolicyImage>
                                                <PolicyTitle>
                                                      Dealer Policy
                                                </PolicyTitle>
                                          </PolicyBox>
                                    </Link>
                              </div>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
};

export default PolicySwiper;
