import styled from "styled-components";
export const PolicyBg = styled.div`
      background-color: #e3eff6;
      border-radius: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const PolicyUnderLine = styled.div`
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
`;
export const PolicyBox = styled.div``;
export const PolicyImage = styled.div`
      width: 100px;

      img {
            width: 100%;
            height: 100%;
      }
`;

export const PolicyTitle = styled.h4`
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      color: #222;
`;
