export const lightTheme = {
      primary: "#25366F",
      secondary: "FFCB05",
      white: "#fff",
      black: "#000000",
      lightBlack: "#00000055",
      lightBlue: "#149DCA",
      red: "#e9252b",
      lightBackground: "#e3eff6",
      soraFont: "'Sora', sans-serif",
      robotoFont: "'Roboto', sans-serif",
      barlowFont: "'Barlow', sans-serif",
};

export const DarkTheme = {
      black: "#000",
};
