import React from "react";
import { Container, Row } from "react-bootstrap";
import "./style.css";

import { socialcontents } from "../../../Data/socialcontent";
import HomePost from "../../../../components/frontend/Home/components/HomePost";
import Marquee from "react-fast-marquee";
import CustomHeadline from "../../../../components/CustomHeadline";

const HomeBanner = () => {
  return (
    <>
      <div className="position-relative sectionMargin">
        <div className="section-banner">
          <div className="banner-inner">
            <Container>
              <CustomHeadline
                subtitle={"Social Media"}
                title={"Plumber"}
                showButton={true}
                variant={true}
                right={true}
                 moreLink="/digital-media"
              />
              <Marquee
                gradient={false}
                speed={50}
                className="container-marquee"
              >
                <div className="row-single mt-4">
                  {socialcontents.map((item, index) => (
                    <HomePost key={index} data={item} />
                  ))}
                </div>
              </Marquee>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
