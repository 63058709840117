import React, { useState } from "react";
import {
      CategoryContainer,
      CategoryImage,
      CategoryList,
      CategoryListItem,
      CategoryTitle,
      DropDownContainer,
      SubCategoryContainer,
      SubCategoryContent,
      SubCategoryItem,
} from "./styles";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const DropDown = ({ loading, categories }) => {
      const [hoveredItem, setHoveredItem] = useState(4);

      return (
            <>
                  <DropDownContainer>
                        <CategoryContainer>
                              <Row className="h-100">
                                    <Col lg={4}>
                                          <CategoryList>
                                                {!loading
                                                      ? categories?.map(
                                                              (item, index) => (
                                                                    <CategoryListItem
                                                                          onMouseEnter={() =>
                                                                                setHoveredItem(
                                                                                      index
                                                                                )
                                                                          }
                                                                          onMouseLeave={() =>
                                                                                setHoveredItem(
                                                                                      index
                                                                                )
                                                                          }
                                                                          className={
                                                                                hoveredItem ===
                                                                                index
                                                                                      ? "active"
                                                                                      : ""
                                                                          }
                                                                    >
                                                                          <Link
                                                                                to={`/products/${item.slug}/category`}
                                                                          >
                                                                                {
                                                                                      item.title
                                                                                }
                                                                          </Link>
                                                                    </CategoryListItem>
                                                              )
                                                        )
                                                      : null}
                                          </CategoryList>
                                    </Col>
                                    <Col lg={8} className="h-100">
                                          <SubCategoryContent>
                                                {categories?.map(
                                                      (item, index) =>
                                                            item?.subCategory ? (
                                                                  <SubCategoryContainer
                                                                        style={{
                                                                              display:
                                                                                    hoveredItem ===
                                                                                    index
                                                                                          ? ""
                                                                                          : "none",
                                                                        }}
                                                                        className="g-3"
                                                                  >
                                                                        {item?.subCategory.map(
                                                                              (
                                                                                    cat,
                                                                                    index
                                                                              ) => (
                                                                                    <SubCategoryItem
                                                                                          lg={
                                                                                                4
                                                                                          }
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <Link
                                                                                                to={`/products/${cat.slug}/category`}
                                                                                                className="d-flex flex-column justify-content-center h-100"
                                                                                          >
                                                                                                <CategoryImage>
                                                                                                      <img
                                                                                                            src={
                                                                                                                  cat.image
                                                                                                            }
                                                                                                            alt={
                                                                                                                  cat.title
                                                                                                            }
                                                                                                            className="img-fluid"
                                                                                                      />
                                                                                                </CategoryImage>
                                                                                                <CategoryTitle>
                                                                                                      {
                                                                                                            cat.title
                                                                                                      }
                                                                                                </CategoryTitle>
                                                                                          </Link>
                                                                                    </SubCategoryItem>
                                                                              )
                                                                        )}
                                                                  </SubCategoryContainer>
                                                            ) : (
                                                                  <SubCategoryContainer
                                                                        style={{
                                                                              display:
                                                                                    hoveredItem ===
                                                                                    item.id
                                                                                          ? ""
                                                                                          : "none",
                                                                        }}
                                                                  >
                                                                        <SubCategoryItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                              lg={
                                                                                    5
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={
                                                                                          item.slug
                                                                                    }
                                                                              >
                                                                                    <CategoryImage>
                                                                                          <img
                                                                                                src={
                                                                                                      item.image
                                                                                                }
                                                                                                alt={
                                                                                                      item.title
                                                                                                }
                                                                                                className="img-fluid"
                                                                                          />
                                                                                    </CategoryImage>
                                                                                    <CategoryTitle>
                                                                                          {
                                                                                                item.title
                                                                                          }
                                                                                    </CategoryTitle>
                                                                              </Link>
                                                                        </SubCategoryItem>
                                                                  </SubCategoryContainer>
                                                            )
                                                )}
                                          </SubCategoryContent>
                                    </Col>
                              </Row>
                        </CategoryContainer>
                  </DropDownContainer>
            </>
      );
};

export default DropDown;
