export const aboutCategory = [
      {
            id: 1,
            title: "About Us",
            slug: "about-us",
      },
      {
            id: 2,
            title: "Associations",
            slug: "associations",
      },
      {
            id: 3,
            title: "Sister Concern",
            slug: "sister-concern",
      },
];
export const productCategory = [
      {
            id: 1,
            title: "3M Water Filtration",
            slug: "3m-water-filtration",
      },
      {
            id: 2,
            title: "Air Purifier",
            slug: "air-purifier",
            subCategory: [
                  {
                        id: 1,
                        title: "Air Purifier",
                        slug: "air-purifier",
                  },
                  {
                        id: 2,
                        title: "Dehumidifier",
                        slug: "dehumidifier",
                  },
            ],
      },
      {
            id: 3,
            title: "Heating",
            slug: "heating",
            subCategory: [
                  {
                        id: 1,
                        title: "Electric Water Heater/Gyeser",
                        slug: "electric-water-heater-gyeser",
                  },
                  {
                        id: 2,
                        title: "Danfoss Electric Floor Heating",
                        slug: "danfoss-electric-floor-heating",
                  },
                  {
                        id: 3,
                        title: "Electrical Heater",
                        slug: "electrical-heater",
                  },
                  {
                        id: 4,
                        title: "Hot Water Heat Pump",
                        slug: "hot-water-heat-pump",
                  },
                  {
                        id: 5,
                        title: "Stoves & Fireplaces",
                        slug: "stoves-and-fireplaces",
                  },
                  {
                        id: 6,
                        title: "Thermal Solar Water Heater",
                        slug: "thermal-solar-water-heater",
                  },
            ],
      },
      {
            id: 4,
            title: "Pex Pipes & Fittings",
            slug: "pex-pipes-and-fittings",
      },
      {
            id: 5,
            title: "Sanitization",
            slug: "sanitization",
      },
      {
            id: 6,
            title: "Water Filtration",
            slug: "water-filtration",
      },
      {
            id: 7,
            title: "Waste Management",
            slug: "waste-management",
      },
      {
            id: 8,
            title: "Water Pumps",
            slug: "water-pumps",
      },
];

export const menuLists = [
      {
            id: 1,
            title: "Home",
            slug: "/",
      },
      {
            id: 2,
            title: "About",
            slug: "/about-us",
      },
      {
            id: 3,
            title: "Products",
            slug: "/products",
            children: true,
      },
      {
            id: 4,
            title: "Dealers",
            slug: "/dealer-location",
      },
      {
            id: 5,
            title: "Catalogue",
            slug: "/catalogue",
      },
      {
            id: 6,
            title: "Blogs",
            slug: "/blogs",
      },
      {
            id: 7,
            title: "Contact",
            slug: "/contact-us",
      },
];
