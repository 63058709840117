import styled from "styled-components";

export const AssociatesWrapper = styled.div`
      padding: 5px;

      @media (max-width: 768px) {
            .mySwiper-associates {
                  padding: 10px 0;
            }
      }

      @media (max-width: 480px) {
            .mySwiper-associates {
                  padding: 5px 0;
            }
      }
`;

export const AssociatesText = styled.div`
      margin-bottom: 10px;

      h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--primary-color);
            margin-bottom: 0;
      }

      @media (max-width: 768px) {
            h3 {
                  font-size: 16px;
            }
      }

      @media (max-width: 480px) {
            h3 {
                  font-size: 14px;
            }
      }
`;

export const AssociatesImage = styled.div`
      height: 80px;
      margin-bottom: 0.5rem;

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.3s ease-in-out;
      }

      @media screen and (max-width: 568px) {
            height: 100%;
            width: 100%;
            margin-bottom: 0;
      }
`;

export const AssociatesCard = styled.div`
      border-right: 1px solid #cccccc;
      overflow: hidden;
      transition: transform 0.1s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;
      height: 100%;
      transition: all 0.3s ease-in-out;

      &:hover {
            transform: translateY(-2px);

            ${AssociatesImage} {
                  img {
                        transform: scale(1.01);
                  }
            }
      }

      @media (max-width: 768px) {
            padding: 15px;
      }

      @media (max-width: 480px) {
            padding: 10px;
      }
`;
