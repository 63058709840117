import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LiaAwardSolid } from "react-icons/lia";
import { FaRegHandshake } from "react-icons/fa6";
import { HiOutlineLightBulb } from "react-icons/hi";
import "./style.css";
import Associates from "./Associates";
import CustomHeadline from "../../../../components/CustomHeadline";
import { FeatureItem } from "./style";

const ValuesCertificates = () => {
      const features = [
            {
                  colorClass: "quality-bg",
                  title: "We Believe In",
                  subtitle: "QUALITY",
                  Icon: LiaAwardSolid,
            },
            {
                  colorClass: "trust-bg",
                  title: "We Believe In",
                  subtitle: "TRUST",
                  Icon: FaRegHandshake,
            },
            {
                  colorClass: "innovation-bg",
                  title: "We Believe In",
                  subtitle: "INNOVATION",
                  Icon: HiOutlineLightBulb,
            },
      ];

      return (
            <div className="home-values-section">
                  <Container>
                        <Row className="align-items-center">
                              <Col lg={6}>
                                    <Row className="g-0 text-center">
                                          {features.map((feature, index) => (
                                                <Col
                                                      className="col-4"
                                                      lg={4}
                                                      md={6}
                                                      key={index}
                                                >
                                                      <FeatureItem
                                                            className={`${
                                                                  index ===
                                                                  features?.length -
                                                                        1
                                                                        ? "border-radius-last"
                                                                        : ""
                                                            } ${
                                                                  index === 0
                                                                        ? "border-radius-first"
                                                                        : ""
                                                            } ${
                                                                  feature.colorClass
                                                            }`}
                                                      >
                                                            <feature.Icon className="feature-icon" />
                                                            <h5>
                                                                  {
                                                                        feature.title
                                                                  }
                                                            </h5>
                                                            <h3>
                                                                  {
                                                                        feature.subtitle
                                                                  }
                                                            </h3>
                                                            <div className="arrow-icon">
                                                                  <span>
                                                                        &rarr;
                                                                  </span>
                                                            </div>
                                                      </FeatureItem>
                                                </Col>
                                          ))}
                                    </Row>
                              </Col>
                              <Col lg={6}>
                                    <div className="partners-section">
                                          <CustomHeadline
                                                titleColor={"#F7BF23"}
                                                subtitleColor={"#fff"}
                                                title="Our Values and Certifications"
                                                right={true}
                                                white={true}
                                          />
                                          <Row className="text-center">
                                                <Associates />
                                          </Row>
                                    </div>
                              </Col>
                        </Row>
                  </Container>
            </div>
      );
};

export default ValuesCertificates;
