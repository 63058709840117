import React from "react";
import axios from "axios";
import { createContext, useState, useEffect } from "react";

const CareerContext = createContext({
      careers: [],
      loading: false,
});

export function CareerContextProvider(props) {
      const [careers, setCareers] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/careers`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCareers(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const context = {
            careers: careers,
            loading: loading,
      };

      return (
            <CareerContext.Provider value={context}>
                  {props.children}
            </CareerContext.Provider>
      );
}

export default CareerContext;
