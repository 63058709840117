import styled from "styled-components";

export const Table = styled.table`
  thead tr th {
    background: var(--primary-color);
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 400;
  }
  td {
    background-color: #fff;
    color: #222;
    font-weight: 300;
  }
`;
