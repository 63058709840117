import React, { useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { Col, Nav } from "react-bootstrap";
import CustomHeadline from "../../components/CustomHeadline";
import { GalleryContainer, GalleryRow } from "./styles";
import PhotoGallery from "./components/PhotoGallery";
import VideoGallery from "./components/VideoGallery";
import "./style.css";
import axios from "axios";
import { MetaContext } from "../../Store";

const GalleryPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("galleries");

      const [activeGallery, setActiveGallery] = useState("photo");

      const handleGalleryClick = (gallery) => {
            setActiveGallery(gallery);
      };

      const [loading, setLoading] = useState(false);
      const [galleries, setGalleries] = useState([]);
      const [videos, setVideos] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/galleries`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setGalleries(response.data.galleries);
                              setVideos(response.data.videos);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <CustomBreadcrumb
                        subtitle={"GALLERY"}
                        title={"Our GALLERY"}
                  />
                  <div className="sectionMargin marginBottom">
                        <GalleryContainer>
                              <CustomHeadline
                                    title="Our Gallery"
                                    textAlign={"center"}
                              />
                              <Nav
                                    variant="pills"
                                    className="customsectionPadding justify-content-center"
                              >
                                    <Nav.Item>
                                          <Nav.Link
                                                style={{
                                                      padding: "10px 30px",
                                                }}
                                                href="#"
                                                active={
                                                      activeGallery === "photo"
                                                }
                                                onClick={() =>
                                                      handleGalleryClick(
                                                            "photo"
                                                      )
                                                }
                                          >
                                                Photo Gallery
                                          </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                          <Nav.Link
                                                style={{
                                                      padding: "10px 30px",
                                                }}
                                                href="#"
                                                active={
                                                      activeGallery === "video"
                                                }
                                                onClick={() =>
                                                      handleGalleryClick(
                                                            "video"
                                                      )
                                                }
                                          >
                                                Video Gallery
                                          </Nav.Link>
                                    </Nav.Item>
                              </Nav>
                              <GalleryRow>
                                    <Col lg="12">
                                          {activeGallery === "photo" ? (
                                                <PhotoGallery
                                                      photos={galleries}
                                                />
                                          ) : (
                                                <VideoGallery videos={videos} />
                                          )}
                                    </Col>
                              </GalleryRow>
                        </GalleryContainer>
                  </div>
            </>
      );
};

export default GalleryPage;
