import React, { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./style.css";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import CustomHeadline from "../../../../components/CustomHeadline";
import FaqSide from "../FAQSide";

export const FAQItem = ({ question, answer, id, activeKey, setActiveKey }) => {
      const handleToggle = () => {
            setActiveKey(activeKey === id ? null : id);
      };

      return (
            <Accordion activeKey={activeKey}>
                  <Accordion.Item className="border-rounded-0" eventKey={id}>
                        <Accordion.Header onClick={handleToggle}>
                              {question}
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                              {answer}
                        </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
      );
};

const FAQ = ({ faqs }) => {
      const [activeKey, setActiveKey] = useState(null);

      return (
            <div className="position-relative">
                  <CustomBreadcrumb title={"FAQs"} subtitle={"FAQs"} />
                  <div className="sectionMargin marginBottom">
                        <CustomHeadline
                              subtitle={"Have a question?"}
                              title={"Plumbing FAQs"}
                              textAlign={"center"}
                        />
                        <Container className="mt-5">
                              <Row className="g-0">
                                    <Col md={8} className="px-3">
                                          {faqs?.map((item, i) => (
                                                <>
                                                      <h4
                                                            className={
                                                                  i === 0
                                                                        ? "mt-0"
                                                                        : "mt-4 mb-1"
                                                            }
                                                      >
                                                            {item?.title}
                                                      </h4>
                                                      {item?.faqs?.map(
                                                            (faq, index) => (
                                                                  <FAQItem
                                                                        key={
                                                                              index
                                                                        }
                                                                        id={
                                                                              index
                                                                        }
                                                                        question={
                                                                              faq?.question
                                                                        }
                                                                        answer={
                                                                              faq?.answer
                                                                        }
                                                                        activeKey={
                                                                              activeKey
                                                                        }
                                                                        setActiveKey={
                                                                              setActiveKey
                                                                        }
                                                                  />
                                                            )
                                                      )}
                                                </>
                                          ))}
                                    </Col>
                                    <Col md={4} className="px-3">
                                          <FaqSide />
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </div>
      );
};

export default FAQ;
