import React, { useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { Col, Container, Modal } from "react-bootstrap";
import {
      CatalogueContent,
      CatalogueImg,
      CatalogueRow,
      SingleCatalogue,
} from "./styles";
import { Link } from "react-router-dom";
import axios from "axios";
import CatalogueView from "./CatalogueView";
import { MetaContext } from "../../Store";

const CataloguePage = () => {
      const [loading, setLoading] = useState(false);
      const [catalogues, setCatalogues] = useState([]);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("catalogues");

      const [slug, setSlug] = useState("");
      const [title, setTitle] = useState("");
      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/catalogues`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCatalogues(response.data.catalogues);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleCatalogue = (dataSlug, dataTitle) => {
            setTitle(dataTitle);
            setSlug(dataSlug);
            setShow(true);
      };

      const handleDownload = async (productId) => {
            try {
                  const response = await axios.get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/download-image/${productId}`,
                        {
                              responseType: "blob", // Ensures the file is fetched as binary data
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  );

                  // Determine the file extension based on Content-Type
                  const contentType = response.headers["content-type"];
                  let extension = "";

                  if (contentType.startsWith("image")) {
                        extension = contentType.split("/")[1]; // e.g., "jpeg" for "image/jpeg"
                  } else if (contentType === "application/pdf") {
                        extension = "pdf";
                  } else {
                        console.error("Unsupported file type:", contentType);
                        return;
                  }

                  // Create a URL for the blob response
                  const url = window.URL.createObjectURL(
                        new Blob([response.data])
                  );

                  // Create a download link
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = `${productId}.${extension}`; // Use dynamic extension
                  link.style.display = "none";

                  document.body.appendChild(link);
                  link.click();

                  // Clean up
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(url);
            } catch (error) {
                  console.error("Download failed:", error);
            }
      };

      return (
            <>
                  <CustomBreadcrumb title="CATALOGUE" subtitle={"CATALOGUE"} />
                  <div className="sectionPadding">
                        <Container>
                              <CatalogueRow className="g-4">
                                    {catalogues?.map((catalogue, i) => (
                                          <Col lg="4" key={i}>
                                                <SingleCatalogue>
                                                      <CatalogueImg
                                                            onClick={() =>
                                                                  handleCatalogue(
                                                                        catalogue.slug,
                                                                        catalogue.title
                                                                  )
                                                            }
                                                      >
                                                            <img
                                                                  className="img-fluid"
                                                                  src={
                                                                        catalogue.image
                                                                  }
                                                                  alt=""
                                                            />
                                                      </CatalogueImg>
                                                      <CatalogueContent className="d-flex justify-content-between align-items-center mt-2">
                                                            <h4>
                                                                  {" "}
                                                                  <Link
                                                                        to="#"
                                                                        onClick={() =>
                                                                              handleCatalogue(
                                                                                    catalogue.slug,
                                                                                    catalogue.title
                                                                              )
                                                                        }
                                                                  >
                                                                        {
                                                                              catalogue.title
                                                                        }
                                                                  </Link>
                                                            </h4>
                                                            <a
                                                                  onClick={() =>
                                                                        handleDownload(
                                                                              catalogue.slug
                                                                        )
                                                                  }
                                                            >
                                                                  {" "}
                                                                  <i className="bx bx-download"></i>
                                                            </a>
                                                      </CatalogueContent>
                                                </SingleCatalogue>
                                          </Col>
                                    ))}
                              </CatalogueRow>
                        </Container>
                  </div>
                  <Modal
                        style={{ zIndex: "999999999" }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: "0", overflow: "hidden" }}
                        >
                              <CatalogueView slug={slug} />
                        </Modal.Body>
                  </Modal>
                  {/* <CustomFooter /> */}
            </>
      );
};

export default CataloguePage;
