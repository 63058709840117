import React from "react";
import { Input, Textarea } from "./style";

const FormInput = (props) => {
      const { id, onChange, type, ...inputProps } = props;
      return (
            <>
                  {type !== "textarea" ? (
                        <Input {...inputProps} onChange={onChange} />
                  ) : (
                        <Textarea
                              {...inputProps}
                              onChange={onChange}
                              cols="40"
                              rows="10"
                        />
                  )}
            </>
      );
};

export default FormInput;
