import React, { useEffect, useState } from "react";
import { SectionPadding } from "../../../../components/SectionPadding/styles";
import CustomHeadline from "../../../../components/CustomHeadline";
import {
      AwardsContainer,
      AwardsRow,
      SingleAward,
      SingleAwardImg,
      SingleAwardInner,
} from "./styles";
import CommonSwiper from "../../../../components/CommonSwiper";
import { SwiperSlide } from "swiper/react";
import axios from "axios";

const AboutAwardAndRecognition = () => {
      const [loading, setLoading] = useState(false);
      const [achievements, setAchievements] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/achievements`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAchievements(response.data.achievements);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <div className="position-relative sectionMargin">
                  <SectionPadding
                        bgColor="var(--primary-color)"
                        style={{
                              paddingBottom: "4em",
                        }}
                  >
                        <CustomHeadline
                              title="Awards & Achievements"
                              textAlign={"center"}
                              titleColor={"var(--yellow-color)"}
                        />
                        <AwardsContainer className="mt-5">
                              <AwardsRow>
                                    <CommonSwiper>
                                          {achievements?.map((award, i) => (
                                                <SwiperSlide key={i}>
                                                      <SingleAward>
                                                            <SingleAwardInner>
                                                                  <SingleAwardImg>
                                                                        <img
                                                                              src="/awards/achievements/award.jpg"
                                                                              alt=""
                                                                              className="img-fluid"
                                                                        />
                                                                  </SingleAwardImg>
                                                                  <p>
                                                                        {
                                                                              award?.title
                                                                        }
                                                                  </p>
                                                                  <h3>
                                                                        {
                                                                              award?.year
                                                                        }
                                                                  </h3>
                                                            </SingleAwardInner>
                                                      </SingleAward>
                                                </SwiperSlide>
                                          ))}
                                    </CommonSwiper>
                              </AwardsRow>
                        </AwardsContainer>
                  </SectionPadding>
            </div>
      );
};

export default AboutAwardAndRecognition;
