import styled from "styled-components";

export const ContactForm = styled.div`
      background-color: #f2f3f3;
      padding: 32px;
      border-radius: 4px;

      .form-control,
      .form-select {
            padding: 15px 15px 15px 15px;
            font-size: 16px;
            font-weight: 300;
            border-style: solid;
            border-radius: 0;
            border-width: 0px 0px 1px 0px;
            border-color: var(--primary-color);
            background-color: #f2f3f300;
      }

      @media screen and (max-width: 568px) {
            margin-top: 2rem;
      }
`;
