import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SingleGallery } from "../../styles";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import Fancybox from "../../../../components/FancyBox";
import axios from "axios";
import { Helmet } from "react-helmet";

const PhotoLayout = () => {
      const { photoId } = useParams();

      const [loading, setLoading] = useState(false);
      const [gallery, setGallery] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/galleries/${photoId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setGallery(response.data.gallery);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {gallery?.seo_title || gallery?.title}
                              </title>
                              {gallery?.seo_description && (
                                    <meta
                                          name="description"
                                          content={gallery?.seo_description}
                                    />
                              )}
                              {gallery?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={gallery?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb title={gallery?.title}>
                        <Link to="/gallery">GALLERY</Link>
                  </CustomBreadcrumb>
                  <Container className="sectionMargin marginBottom">
                        <Row className="g-5">
                              {gallery?.images?.map((item, i) => (
                                    <Col lg="4" key={i}>
                                          <SingleGallery>
                                                <Fancybox
                                                      options={{
                                                            Carousel: {
                                                                  infinite: false,
                                                            },
                                                      }}
                                                >
                                                      <a
                                                            data-fancybox="gallery"
                                                            href={item?.image}
                                                      >
                                                            <img
                                                                  src={
                                                                        item?.image
                                                                  }
                                                                  alt={
                                                                        gallery?.title
                                                                  }
                                                            />
                                                      </a>
                                                </Fancybox>
                                          </SingleGallery>
                                    </Col>
                              ))}
                        </Row>
                  </Container>
            </>
      );
};

export default PhotoLayout;
