import React, { useEffect, useState } from "react";
import { testomonial } from "../../data/testomonial";
import "./style.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import axios from "axios";

const SingleTestomonial = () => {
      const [loading, setLoading] = useState(false);
      const [testimonials, setTestimonials] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTestimonials(response.data.testimonials);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <div className="testomonial-wrapper ">
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        className="mySwiper"
                        autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                        }}
                        loop={true}
                        breakpoints={{
                              640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                              },
                              1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                              },
                        }}
                        modules={[Autoplay, Pagination, EffectFade]}
                  >
                        {testimonials?.map((item) => (
                              <SwiperSlide className="h-100">
                                    <div className="single-testomonial h-100">
                                          <div className="testomonial-img mb-2">
                                                <img
                                                      src={item?.image}
                                                      alt={`Testimonial by ${item?.name}`}
                                                      className="img-fluid"
                                                />
                                          </div>
                                          <div className="font-small mt-1 custom">
                                                {item?.name}
                                          </div>
                                          <div
                                                className=" mt-2 content"
                                                style={{
                                                      fontSize: "15px",
                                                      fontWeight: "300",
                                                }}
                                          >
                                                {item?.message}
                                          </div>
                                    </div>
                              </SwiperSlide>
                        ))}
                  </Swiper>
            </div>
      );    
};

export default SingleTestomonial;
