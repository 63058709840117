import React from "react";
import CustomHeadline from "../../../../components/CustomHeadline";
import SingleTestomonial from "./components/SingleTestomonial";
import "./style.css";
import { Col, Row } from "react-bootstrap";
import {
      SectionTestimonials,
      Testimonial,
      TestimonialContent,
      WaterDrop1,
} from "./style";

const HomeTestomonials = () => {
      return (
            <Testimonial>
                  <WaterDrop1>
                        <img
                              src="/banners/waterdrop2.png"
                              alt=""
                              className="img-fluid"
                        />
                  </WaterDrop1>
                  <SectionTestimonials>
                        <div className="inner-testomonials container">
                              <Row>
                                    <Col lg={5}>
                                          <TestimonialContent>
                                                <CustomHeadline
                                                      subtitle={
                                                            "Client Testimonial"
                                                      }
                                                      title="Plumber"
                                                      textAlign={"left"}
                                                      subtitleColor={"#fff"}
                                                      titleColor={"#F7BF23"}
                                                      right={true}
                                                />
                                                <p className="text-white mt-3">
                                                      See What our Clients talk
                                                      about us. They commend our
                                                      team for delivering
                                                      exceptional results and
                                                      exceeding expectations
                                                      every time.
                                                </p>
                                          </TestimonialContent>
                                    </Col>
                                    <Col lg={7}>
                                          <SingleTestomonial />
                                    </Col>
                              </Row>
                        </div>
                  </SectionTestimonials>
            </Testimonial>
      );
};

export default HomeTestomonials;
