import styled from "styled-components";

export const TabContentBox = styled.div`
	background-image: url(/products/background.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	li {
		font-size: 18px;
		color: #fff;
		list-style: none;
	}
	i {
		font-size: 21px;
	}
	h4 {
		text-align: center;
		color: #fff;
		margin-bottom: 28px;
		font-size: 24px;
	}
`;
