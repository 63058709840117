import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CSRRow = styled(Row)`
      h2 {
            color: var(--primary-color);
            margin-bottom: 1rem;
            font-size: 24px;
      }
`;
export const SingleCSR = styled.div`
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
      min-height: 150px;
      height: 100%;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
      border-radius: 5px;
      h4 {
            font-size: 22px;
            font-weight: 400;
      }
`;

export const CSRContainer = styled(Container)``;

export const CSRProgrammeRow = styled(Row)``;

export const SingleProgramme = styled(Link)`
      h4 {
            font-weight: 400;
            margin-top: 0.7rem;
            font-size: 18px;
            color: ${(props) => props.theme.black};
      }

      &:hover {
            h4 {
                  color: ${(props) => props.theme.primary};
            }
      }
`;
export const SingleProgrammeImg = styled.div`
      border-radius: 5px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
            rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      width: 100%;
      height: 300px;

      img {
            width: 100%;
            object-fit: cover;
            height: 100%;
      }
`;
