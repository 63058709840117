import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, EffectFade, Mousewheel } from "swiper/modules";

const CategorySlider = () => {
      const images = [
            "/category/1.png",
            "/category/2.png",
            "/category/3.png",
            "/category/4.png",
            "/category/5.png",
            "/category/6.png",
      ];

      return (
            <Swiper
                  slidesPerView={2}
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                        delay: 0,
                  }}
                  speed={5000}
                  modules={[Autoplay, Pagination, EffectFade, Mousewheel]}
                  mousewheel={false}
                  className="mySwiper"
                  breakpoints={{
                        640: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                        },
                        768: {
                              slidesPerView: 2,
                              spaceBetween: 15,
                        },
                        1024: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                        },
                  }}
            >
                  {images.map((image, index) => (
                        <SwiperSlide key={index} style={{ width: "auto" }}>
                              <div className="slider__image">
                                    <img
                                          src={image}
                                          alt={`Category ${index + 1}`}
                                    />
                              </div>
                        </SwiperSlide>
                  ))}
            </Swiper>
      );
};

export default CategorySlider;
