import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
      AssociatesCard,
      AssociatesImage,
      AssociatesText,
      AssociatesWrapper,
} from "./style";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

const Associates = () => {
      return (
            <AssociatesWrapper>
                  <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        loop={true}
                        autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination, EffectFade]}
                        className="mySwiper-associates"
                  >
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <AssociatesText>
                                          <h3>ISO</h3>
                                    </AssociatesText>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/iso.jpg"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <div className="associates-text">
                                          <h3>Rohs</h3>
                                    </div>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/rohs-logo.png"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <div className="associates-text">
                                          <h3>NS</h3>
                                    </div>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/ns.png"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <div className="associates-text">
                                          <h3>ISO</h3>
                                    </div>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/logo1.png"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <div className="associates-text">
                                          <h3>EU</h3>
                                    </div>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/eu.png"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                        <SwiperSlide className="h-100">
                              <AssociatesCard>
                                    <div className="associates-text">
                                          <h3>NS</h3>
                                    </div>
                                    <AssociatesImage>
                                          <img
                                                src="/awards/ns.png"
                                                alt="Image 1"
                                                className="img-fluid"
                                          />
                                    </AssociatesImage>
                              </AssociatesCard>
                        </SwiperSlide>
                  </Swiper>
            </AssociatesWrapper>
      );
};

export default Associates;
