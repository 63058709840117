import React, { useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
      CSRContainer,
      CSRProgrammeRow,
      SingleProgramme,
      SingleProgrammeImg,
} from "./styles";
import { Col } from "react-bootstrap";
import CustomHeadline from "../../components/CustomHeadline";
import { SectionPadding } from "../../components/SectionPadding/styles";
import axios from "axios";
import { MetaContext } from "../../Store";

const CSRPage = () => {
      const [loading, setLoading] = useState(false);
      const [csrs, setCsrs] = useState([]);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("csr");

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/csrs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCsrs(response.data.csrs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <CustomBreadcrumb
                        subtitle={"CSR"}
                        title={"Corporate Social Responsibility"}
                  />
                  <div className="sectionMargin">
                        <div className="sectionMargin position-relative">
                              <SectionPadding className="custom-padding pt-0">
                                    <CSRContainer>
                                          <CustomHeadline
                                                title="CSR Programmes"
                                                textAlign={"center"}
                                          />
                                          <CSRProgrammeRow className="mt-5">
                                                {csrs?.map((data, i) => (
                                                      <Col lg="4" key={i}>
                                                            <SingleProgramme
                                                                  to={`/csr/${data?.slug}`}
                                                            >
                                                                  <SingleProgrammeImg>
                                                                        <img
                                                                              src={
                                                                                    data?.image
                                                                              }
                                                                              className="img-fluid"
                                                                              alt={
                                                                                    data?.title
                                                                              }
                                                                        />
                                                                  </SingleProgrammeImg>
                                                                  <h4>
                                                                        {
                                                                              data?.title
                                                                        }
                                                                  </h4>
                                                            </SingleProgramme>
                                                      </Col>
                                                ))}
                                          </CSRProgrammeRow>
                                    </CSRContainer>
                              </SectionPadding>
                        </div>
                  </div>
            </>
      );
};

export default CSRPage;
