import React from "react";
import "./style.css";
import CommonButton from "../CommonButton";
const CustomHeadline = ({
      subtitle,
      title,
      subtitleColor,
      titleColor,
      textAlign,
      showButton,
      right,
      white,
      moreLink,
}) => {
      const subtitleStyle = {
            color: subtitleColor || "var(--text-headline01)",
      };
      const titleStyle = {
            color: titleColor || "var(--primary-color)",
      };
      const headlineStyle = {
            textAlign: textAlign || "left",
      };
      const whiteStyle = {
            borderBottom: white && "2px solid #fff",
      };
      const gap = right ? "ga-0" : "gap-3";

      return (
            <div
                  className={`custom-headline__wrapper  ${
                        showButton
                              ? "d-flex w-100 justify-content-between align-items-center"
                              : ""
                  }`}
            >
                  {/* Your component content goes here */}
                  <div className="custom-headline" style={headlineStyle}>
                        <div
                              className={` main d-flex ${gap} align-items-center justify-content-center`}
                              style={titleStyle}
                        >
                              {right ? (
                                    " "
                              ) : (
                                    <span
                                          className="left"
                                          style={{ whiteStyle }}
                                    ></span>
                              )}
                              <div className="custom__title">
                                    {title} <br />
                                    {subtitle && (
                                          <span
                                                style={subtitleStyle}
                                                className="border-0"
                                          >
                                                [ {subtitle} ]
                                          </span>
                                    )}
                              </div>
                              <span
                                    className="right"
                                    style={{ whiteStyle }}
                              ></span>
                        </div>
                  </div>
                  {showButton && (
                        <CommonButton
                              slug={moreLink || "#"}
                              title={"See More"}
                              variant1y={true}
                        />
                  )}
            </div>
      );
};

export default CustomHeadline;
