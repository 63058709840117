import { Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const AwardsContainer = styled(Container)``;
export const SingleAward = styled.div`
  border: 8px solid var(--gray-color);
  background-color: #f2f2f2;
  padding: 1rem 1.2rem;
  min-height: 350px;
  height: 100%;
  text-align: center;
  h3 {
    position: absolute;
    font-size: 34px;
    color: #ddd;
    font-weight: 700;
    transform: translate(-50%, 0px);
    left: 50%;
    bottom: 22px;
  }
  p {
    padding: 22px 12px;
  }
`;

export const AwardsRow = styled(Row)``;

export const SingleAwardImg = styled.div`
  width: 100px;
  padding-top: 24px;
  margin: 3px auto;
  img {
    width: 100%;
    height: 100%;
  }
`;
export const SingleAwardInner = styled.div`
  position: relative;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
`;
