import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";

const SingleBlogWidget = ({ item }) => {
      return (
            <div className="blog-widget">
                  <Col md="12">
                        <div className="sidebar-recent">
                              <div className="recent-img">
                                    <Link to={`/blogs/${item?.slug}`}>
                                          <img
                                                src={item?.image}
                                                alt={item?.title}
                                                className="img-fluid"
                                          />
                                    </Link>
                              </div>
                              <div className="recent-content">
                                    <div className="meta-date font-xxs">
                                          <i className="bx bx-calendar me-1"></i>
                                          {item?.date}
                                    </div>
                                    <Link to={`/blogs/${item?.slug}`}>
                                          <div className="recent-title mt-2">
                                                {item?.title}
                                          </div>
                                    </Link>
                              </div>
                        </div>
                  </Col>
            </div>
      );
};

export default SingleBlogWidget;
