import React, { useContext } from "react";
import Contact from "./components/Contact";
import MapSection from "./components/MapSection";
import { MetaContext } from "../../Store";

const ContactPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("contact-us");
      
      return (
            <>
                  <Contact />
                  <MapSection />
            </>
      );
};

export default ContactPage;
