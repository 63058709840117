import { Nav } from "react-bootstrap";
import styled from "styled-components";

export const ProductTabBox = styled.div`
      .nav {
            border: 0;
      }
      .navbar {
            background: ${(props) => props.theme.primary};
            height: 228px;
      }
      .nav-item {
            width: 100%;
      }
      .nav-item a {
            color: #fff;
            width: 100%;
            position: relative;
            font-size: 18px;
            font-weight: 400;
            padding: 15px 0px;

            &:hover {
                  color: #fff;
            }
            &::after {
                  content: "";
                  width: 0;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  z-index: -1;
                  background: #027bff;
                  transition: all 0.4s ease;
                  color: #fff;
            }
            &:hover::after {
                  width: 100%;
                  color: #027bff;
            }
      }
      .nav-link.active {
            color: #fff;
            border-radius: 0;
            background: #027bff;
      }
      .nav-tabs .nav-link {
            border: 0;
      }
`;
export const ProductNav = styled(Nav)`
      width: 100%;
`;
