import styled from "styled-components";

export const WhyUsBox = styled.section``;
export const WhyUsContent = styled.div`
      h1 {
            font-size: 25px;
            font-weight: 600;
            text-transform: capitalize;
            color: #222;
      }
      p {
            line-height: 22px;
            color: var(--gray-color);
      }
`;

export const CompanyImage = styled.div`
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      video {
            height: 320px;
            width: 100%;
            transform: scale(1.4);
      }
      &::before {
            width: 100%;
            height: 200%;
            background: linear-gradient(
                  180deg,
                  rgba(15, 15, 15, 0) 0%,
                  rgba(15, 15, 15, 0.4) 20.83%,
                  rgba(15, 15, 15, 0.7) 38.75%,
                  #0f0f0f 63.33%
            );
            content: "";
            top: 0;
            position: absolute;
            bottom: 0;
            left: 0;
      }

      @media screen and (max-width: 568px) {
            video {
                  height: 310px;
                  transform: scale(1.9);
            }
      }
`;
