import React from "react";
import SingleBlogWidget from "../SingleBlogWidget";
import { Link } from "react-router-dom";

const SidePanel = ({ loading, blogs, categories, blog }) => {
      return (
            <div className="custom-sticky">
                  {" "}
                  <div className="widget-container widget-custom-change">
                        <div className="recent-post ">Categories</div>
                        {categories?.map((item, index) => (
                              <p className="recent-post-categories">
                                    <Link
                                          to={`/blogs/${item?.slug}/category`}
                                          key={index}
                                    >
                                          {item?.title}{" "}
                                          <small>({item?.products})</small>
                                    </Link>
                              </p>
                        ))}
                  </div>
                  {blog && (
                        <div className="widget-container">
                              <div className="recent-post ">Related Posts</div>
                              {blogs.map((item, index) => (
                                    <SingleBlogWidget key={index} item={item} />
                              ))}
                        </div>
                  )}
            </div>
      );
};

export default SidePanel;
