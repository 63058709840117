import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const SocialMedia = styled(Row)`
      display: flex;
      flex-wrap: wrap;
`;

export const SocialPostCard = styled(Col)``;

export const SocialPostImage = styled.div`
      width: 100%;
      padding: 0px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      height: 380px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
            rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      border-radius: 15px;

      img {
            border-radius: 15px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
      }

      img:hover {
            transform: scale(1.05);
      }

      .title-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            font-weight: 400;
            color: #fff;
            opacity: 0;
            text-align: center;
            z-index: 2;
            transition: opacity 0.3s ease-in-out;
            pointer-events: none;
      }

      &:hover .title-overlay {
            opacity: 1;
      }

      &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            border-radius: 15px;
            transition: opacity 0.3s ease-in-out;
            z-index: 1;
      }

      &:hover::before {
            opacity: 1;
      }
`;

export const SocialPostTitle = styled.h4`
      text-wrap: no-wrap;
      font-size: 16px;
      text-align: justify;
      text-transform: capitalize;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      line-height: 1.2;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
`;
export const SocialPostContent = styled.div`
      display: flex;
      align-items: center;
      text-align: center;
      gap: 20px;

      div {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 10px;
      }
      span {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
            font-size: 17px;
      }
`;
export const SocialTitle = styled.div`
      text-align: justify;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 2rem;
`;

export const CloseButton = styled.button`
      background-color: #22222299;
      color: white;
      border: none;
      border-radius: 50rem;
      padding: 5px 5px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      position: absolute;
      top: 10px;
      right: 10px;

      &:hover {
            background-color: #222222;
            transform: scale(1.05);
            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
      }

      &:active {
            transform: scale(0.95);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

      &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(244, 67, 54, 0.4);
      }
`;

export const SocialMediaDetail = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
            rgb(209, 213, 219) 0px 0px 0px 1px inset;
      position: sticky;
      top: 120px;
`;

export const SocialMediaContent = styled.div`
      padding: 10px;
      padding-top: 0;

      p {
            margin: 0;
      }
`;
