import React, { useEffect, useState } from "react";
import { HomeBlogSection } from "./styles";
import { Container } from "react-bootstrap";
import CustomHeadline from "../../../../CustomHeadline";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import CommonBlogBox from "../../../../CommonBlogBox";
import axios from "axios";

const HomeBlogs = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setBlogs(response.data.blogs);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <HomeBlogSection>
                        <Container>
                              <CustomHeadline
                                    subtitle={"Blogs & News"}
                                    title={"Plumber "}
                                    showButton={true}
                                    variant={true}
                                    right={true}
                                    moreLink="/blogs"
                              />
                              <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    pagination={false}
                                    autoplay={{
                                          delay: 2500,
                                          disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    breakpoints={{
                                          640: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                          },
                                          768: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                          },
                                          1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                          },
                                    }}
                                    modules={[Autoplay, Pagination, EffectFade]}
                                    className="mySwiper mt-5"
                              >
                                    {blogs.map((item, index) => (
                                          <SwiperSlide key={index}>
                                                <CommonBlogBox
                                                      title={item.title}
                                                      image={item.image}
                                                      slug={item.slug}
                                                      date={item.date}
                                                      category={
                                                            item.category_name
                                                      }
                                                />
                                          </SwiperSlide>
                                    ))}
                              </Swiper>
                        </Container>
                  </HomeBlogSection>
            </>
      );
};

export default HomeBlogs;
