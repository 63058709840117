import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../frontend/Home";
import FAQ from "../frontend/FAQ";
import ContactPage from "../frontend/ContactPage";
import BlogPage from "../frontend/BlogPage";
import SingleBlogDetail from "../frontend/BlogPage/components/SingleBlogDetail";
import CareerPage from "../frontend/CareerPage";
import CareerJobInfo from "../frontend/CareerPage/components/CareerJobInfo";
import CareerForm from "../frontend/CareerPage/components/CareerForm";
import AboutPage from "../frontend/About";
import CSRPage from "../frontend/CSR";
import CataloguePage from "../frontend/Catalogue";
import GalleryPage from "../frontend/Gallery";
import PhotoLayout from "../frontend/Gallery/components/PhotoLayout";
import DealerLocationPage from "../frontend/DealerLocationPage";
import CompanyPolicyPage from "../frontend/CompanyPolicyPage";
import ProductLayout from "../frontend/ProductLayout";
import { product } from "../frontend/Data/products";
import DealerInquiry from "../frontend/DelearInquiry/Index";
import ProductPage from "../frontend/Products";
import ProductDetails from "../frontend/ProductDetails";
import BlogCategoryPage from "../frontend/BlogCategoryPage";
import CsrDetail from "../frontend/CsrDetail";
import DigitalMedia from "../frontend/DigitalMedia";

const MainRouter = () => {
      return (
            <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about-us" element={<AboutPage />} />

                  {/* Faqs */}
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/contact-us" element={<ContactPage />} />

                  {/* Blogs */}
                  <Route path="/blogs" element={<BlogPage />} />
                  <Route path="/blogs/:blogId" element={<SingleBlogDetail />} />
                  <Route
                        path="/blogs/:categoryId/category"
                        element={<BlogCategoryPage />}
                  />

                  {/* Career */}
                  <Route path="/careers" element={<CareerPage />} />
                  <Route
                        path="/careers/:jobId"
                        element={<CareerJobInfo detail={true} />}
                  />
                  <Route
                        path="/careers/:careerId/apply-now"
                        element={<CareerForm />}
                  />

                  {/* CSR */}
                  <Route path="/csr" element={<CSRPage />} />
                  <Route path="/csr/:csrId" element={<CsrDetail />} />

                  {/* Catalogue */}
                  <Route path="/catalogue" element={<CataloguePage />} />

                  {/* Gallery */}
                  <Route path="/gallery" element={<GalleryPage />} />
                  <Route
                        path="/gallery/photo-gallery/:photoId"
                        element={<PhotoLayout />}
                  />

                  {/* Dealers */}
                  <Route
                        path="/dealer-location"
                        element={<DealerLocationPage />}
                  />
                  <Route path="/dealer-enquiry" element={<DealerInquiry />} />

                  {/* Product */}
                  <Route path="/products" element={<ProductPage />} />
                  <Route
                        path="/products/:categoryId/category"
                        element={<ProductPage />}
                  />
                  <Route
                        path="/products/:productId"
                        element={<ProductDetails />}
                  />
                  {/* <Route
                        path="/cpvc"
                        element={<ProductLayout product={product} />}
                  /> */}

                  {/* Terms & Condition */}
                  <Route
                        path="/terms-and-condition"
                        element={
                              <CompanyPolicyPage slug="terms-and-condition" />
                        }
                  />
                  <Route
                        path="/privacy-policy"
                        element={<CompanyPolicyPage slug={"privacy-policy"} />}
                  />
                  <Route
                        path="/dealer-policy"
                        element={<CompanyPolicyPage slug={"dealer-policy"} />}
                  />
                  {/* digital media */}
                  <Route path="/digital-media" element={<DigitalMedia />} />
                  <Route
                        path="/digital-media/:digitalId"
                        element={<DigitalMedia />}
                  />
            </Routes>
      );
};

export default MainRouter;
