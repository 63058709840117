import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

const CsrDetail = () => {
      const { csrId } = useParams();
      const [loading, setLoading] = useState(false);
      const [csr, setCsr] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/csrs/${csrId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCsr(response.data.csr);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [csrId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{csr?.seo_title || csr?.title}</title>
                              {csr?.seo_description && (
                                    <meta
                                          name="description"
                                          content={csr?.seo_description}
                                    />
                              )}
                              {csr?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={csr?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb title={csr?.title}>
                        <Link to="/csr" className="position-relative">
                              CSR
                        </Link>
                  </CustomBreadcrumb>
                  <div className="sectionMargin marginBottom ">
                        <Container>
                              <Row className="justify-content-center">
                                    <Col lg="8">
                                          <div className="blog__detail">
                                                <div className="blog__img">
                                                      <img
                                                            src={csr?.image}
                                                            alt={csr?.title}
                                                            className="img-fluid rounded"
                                                      />
                                                </div>
                                                <h4 className="mt-2">
                                                      {csr?.title}
                                                </h4>
                                                <div
                                                      className="blog__desc  my-4"
                                                      dangerouslySetInnerHTML={{
                                                            __html: csr?.description,
                                                      }}
                                                ></div>
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </>
      );
};

export default CsrDetail;
