import React from "react";
import {
      BlogBoxButton,
      BlogBoxContent,
      BlogBoxImage,
      BlogBoxItem,
      BlogBoxMeta,
      BlogBoxMetaItem,
      BlogBoxTitle,
} from "./styles";
import { Link } from "react-router-dom";

const CommonBlogBox = ({ image, title, slug, date, category }) => {
      return (
            <BlogBoxItem>
                  <BlogBoxImage>
                        <Link to={`/blogs/${slug}`}>
                              <img
                                    src={image}
                                    alt={title}
                                    className="img-fluid"
                                    width="100%"
                                    height="100%"
                              />
                        </Link>
                  </BlogBoxImage>
                  <BlogBoxContent className="mt-2">
                        <BlogBoxTitle>
                              <Link to={`/blogs/${slug}`}>{title}</Link>
                        </BlogBoxTitle>
                        <BlogBoxMeta className="list-inline d-flex gap-3 mt-3">
                              <BlogBoxMetaItem className="list-inline-item d-flex align-items-center gap-2">
                                    <span className="material-symbols-outlined">
                                          calendar_month
                                    </span>{" "}
                                    {date}
                              </BlogBoxMetaItem>
                              <BlogBoxMetaItem className="list-inline-item d-flex align-items-center gap-2">
                                    <span className="material-symbols-outlined">
                                          sell
                                    </span>{" "}
                                    {category}
                              </BlogBoxMetaItem>
                        </BlogBoxMeta>
                  </BlogBoxContent>
            </BlogBoxItem>
      );
};

export default CommonBlogBox;
