import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import axios from "axios";
import PolicyLayout from "./components/PolicyLayout";
import { PolicyBg, PolicyUnderLine } from "./styles";
import { SectionPadding } from "../../components/SectionPadding/styles";
import { Container } from "react-bootstrap";
import PolicySwiper from "./components/PolicySwiper";
import { Helmet } from "react-helmet";

const CompanyPolicyPage = ({ slug }) => {
      const [loading, setLoading] = useState(false);
      const [policy, setPolicy] = useState([]);

      const loadData = async (slug) => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/${slug}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPolicy(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData(slug);
      }, [slug]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {policy?.seo_title || policy?.title}
                              </title>
                              {policy?.seo_description && (
                                    <meta
                                          name="description"
                                          content={policy?.seo_description}
                                    />
                              )}
                              {policy?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={policy?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb
                        title={policy?.title}
                        subtitle={"COMPANY POLICY"}
                  />
                  <SectionPadding className="marginBottom ">
                        <Container>
                              <PolicyBg className="py-4">
                                    <PolicySwiper />
                                    <PolicyUnderLine />

                                    <PolicyLayout
                                          policy={policy}
                                          loading={loading}
                                    />
                              </PolicyBg>
                        </Container>
                  </SectionPadding>
                  {/* <CustomFooter /> */}
            </>
      );
};

export default CompanyPolicyPage;
