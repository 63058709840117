import React, { useEffect, useState } from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import DropDown from "./components/DropDown";
import ScrollUpHeader from "./components/ScrollUpHeader";
import MobileNav from "./components/MobileNav";
import { menuLists } from "../../Data/categories";
import axios from "axios";

const Header = () => {
      const [isSticky, setIsSticky] = useState(false);
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <header className={`main-header `}>
                        {isSticky ? null : <div className="pattern-bg"></div>}

                        <section
                              className={`section-header ${
                                    isSticky ? "sticky" : ""
                              }`}
                        >
                              <Container className="px-0">
                                    <div className="header-container ">
                                          <div className="header-logo d-flex align-items-center">
                                                <Link to={"/"}>
                                                      <img
                                                            src="/banners/white.png"
                                                            alt="Plumber Pipes Pvt. Ltd."
                                                            className="img-fluid"
                                                      />
                                                </Link>
                                          </div>
                                          <div className="header-lists d-flex  ">
                                                <ul className="mb-0 ps-0">
                                                      <li className="">
                                                            <Link to="/">
                                                                  Home
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/about-us">
                                                                  About
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="#">
                                                                  Products
                                                            </Link>
                                                            <DropDown
                                                                  categories={
                                                                        categories
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </li>
                                                      <li>
                                                            <Link to="/dealer-location">
                                                                  Dealers
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/catalogue">
                                                                  Catalogue
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/blogs">
                                                                  Blogs
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/contact-us">
                                                                  Contact
                                                            </Link>
                                                      </li>
                                                </ul>
                                                <div className="header__contact d-flex gap-3 align-items-center pe-3 ms-4">
                                                      <div className="header__contact--icon">
                                                            <img
                                                                  src="/phone-call.png"
                                                                  alt=""
                                                                  className="img-fluid"
                                                                  width={42}
                                                                  height={42}
                                                            />
                                                      </div>
                                                      <div className="header__contant--info">
                                                            <div className="mb-0">
                                                                  <a href="tel:97701536125">
                                                                        Need
                                                                        help?
                                                                        Talk to
                                                                        an
                                                                        expert
                                                                  </a>
                                                            </div>
                                                            <p>
                                                                  + (977)
                                                                  01-5361925
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>
                                          <MobileNav category={menuLists} />
                                    </div>
                              </Container>
                        </section>
                  </header>
                  <ScrollUpHeader loading={loading} categories={categories} />
            </>
      );
};

export default Header;
