import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const HomeProductContainer = styled.div`
      padding: 5rem 0px;
      background-image: url(banners/category-background.png);
      background-size: cover;
      background-position: top left;

      @media screen and (max-width: 568px) {
            padding: 4rem 0;
      }
`;
export const PopularDestinationContainer = styled.section`
      background: #f5f5f5;
      padding: 80px 0px;
      background-image: url(public/banners/category-background.png);
      background-size: cover;
      background-position: top right;

      @media screen and (max-width: 600px) {
            margin: 0 10px;
      }
`;

export const PopularDestinationHeading = styled.div``;
export const GroupTourTag = styled.div`
      position: absolute;
      top: 15px;
      left: 15px;
      color: #ffffff;
      padding: 5px 8px;
      background: #00000040;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      font-size: 14px;
`;
export const GroupDays = styled.div`
      position: absolute;
      z-index: 10;
      bottom: 15px;
      left: 15px;
      color: #ffffff;
      padding: 5px 8px;
      background: #00000040;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      font-size: 14px;
`;
export const GroupContent = styled.div`
      margin-top: 15px;
      text-align: left;
      h4 {
            font-size: 20px;
            font-weight: 500;
      }
      span {
            font-size: 15px;
            font-weight: 600;
      }
      p.location {
            span {
                  font-size: 18px;
            }
      }
      p {
            del {
                  color: #9f9f9f;
                  font-weight: 300;
            }
      }
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
      }
`;
export const PopularDestinationSlider = styled(Swiper)`
      /* height: 100%; */
      width: 100%;
      position: relative;
      .swiper-wrapper {
            flex-direction: column;
      }
      .swiper-slide {
            width: 100% !important;
      }
      .swiper-button-next,
      .swiper-button-prev {
            background-color: #ffffff00;
            color: #22242980;
            border-style: solid;
            border-width: 2px 2px 2px 2px;
            border-color: #22242980;
            border-radius: 50%;
            height: 40px;
            width: 40px;

            &::after {
                  font-size: 14px;
                  color: #1d1d1b;
            }
      }

      .swiper-button-next {
            left: 80px;
      }

      .swiper-button-next,
      .swiper-button-prev {
            top: auto;
            bottom: 0;
      }
`;

export const PopularDestinationContent = styled.div`
      ${"" /* padding-bottom: 60px; */}
      h4 {
            font-size: 18px;
      }
`;
export const PopularDestinationTitle = styled.div`
      span {
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.primary};
            text-transform: uppercase;
            display: block;
            margin-bottom: 15px;
      }
      font-size: 16px;
      line-height: 1.278;
      margin-bottom: 1.5rem;

      p {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #666;
            font-family: "Rajdhani", sans-serif;
      }
`;
export const PopularDestinationDesc = styled.p``;

export const PopularDestinationThumb = styled(Swiper)`
      height: 100%;
      box-sizing: border-box;
      padding: 10px 0;

      .swiper-wrapper {
            padding: 30px 0px;
            padding-bottom: 115px;
      }

      .swiper-slide {
            opacity: 0.4;

            img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
            }
      }

      .swiper-slide-thumb-active {
            opacity: 1;
      }

      @media screen and (max-width: 600px) {
            .swiper-wrapper {
                  padding: 0px;
            }
      }
`;
// First, define PopularDestinationImageContent
export const PopularDestinationImageContent = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0; // Initially hidden
      transform: translateY(20px); // Subtle animation effect
      transition: opacity 0.3s ease, transform 0.3s ease;
`;

// Then define PopularDestinationImage, which references PopularDestinationImageContent
export const PopularDestinationImage = styled.div`
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      img {
            /* border-radius: 10px; */
            transition: transform 0.3s ease;
      }

      // Blue overlay with animation on hover
      &:hover::after {
            background: var(--primary-color); // Blue background
            opacity: 1;
      }

      &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            height: 60px;
            width: 100%;
            content: "";
            /* background: #1619213b; */
            opacity: 0.65;
            border-radius: 0px;
            transition: background 0.3s ease, opacity 0.3s ease;
      }

      // Scale the image slightly on hover
      &:hover img {
            transform: scale(1.05);
      }

      // Show content on hover
      &:hover ${PopularDestinationImageContent} {
            opacity: 1;
            transform: translateY(0);
      }
`;

// Title styling
export const PopularDestinationImageTitle = styled.div`
      font-size: 20px;
      font-weight: 400;
      position: absolute;
      bottom: 15px;
`;

// Icon styling

export const PopularDestinationImageLink = styled.div``;

export const PopularDestinationSwiperSlider = styled(SwiperSlide)`
      position: relative;
      ${
            "" /* -webkit-box-flex: 700px;
      -ms-flex: 700px;
      flex: 700px; * */
      }
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear;
      text-align: center;
      cursor: pointer;
      height: 375px;
      opacity: 1 !important;
      img {
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            z-index: 2;
      }
      &.swiper-slide-thumb-active {
            opacity: 1;
            -webkit-box-flex: 307px;
            -ms-flex: 307px;
            flex: 307px;
            ${PopularDestinationImageContent} {
                  display: flex;
            }
      }
      @media screen and (max-width: 600px) {
            &.swiper-slide {
                  min-width: 120px;
            }
            &.swiper-slide-thumb-active {
                  -webkit-box-flex: 260px;
                  -ms-flex: 260px;
                  flex: 260px;
                  min-width: 260px !important;
            }
      }
`;

export const ProductItem = styled.div``;
