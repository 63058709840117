import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import CareerContext from "../../../../Store/career-lists";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import CustomHeadline from "../../../../components/CustomHeadline";
import { Col, Container, Row } from "react-bootstrap";
import CareerJoblists from "../CareerJobLists";
import CareerDescription from "../CareerDescription";
import { MetaContext } from "../../../../Store";
const CareerJobInfo = ({ detail }) => {
      const { jobId } = useParams();
      const careerCtx = useContext(CareerContext);
      const metaCtx = useContext(MetaContext);
      if (!detail) {
            metaCtx.handleSlug("careers");
      }

      return (
            <>
                  <CustomBreadcrumb title={"Careers"} subtitle={"Careers"} />
                  <div className="sectionMargin marginBottom">
                        <CustomHeadline
                              title={"Join Us"}
                              subtitle={
                                    "Join Us to get the chance to work with our experienced and friendly team."
                              }
                              textAlign={"center"}
                        />
                        <Container className="sectionMargin marginBottom">
                              <Row>
                                    <Col lg="3">
                                          <CareerJoblists
                                                jobs={careerCtx.careers}
                                          />
                                    </Col>
                                    <Col lg="9">
                                          <CareerDescription
                                                detail={detail}
                                                jobId={jobId}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </>
      );
};

export default CareerJobInfo;
