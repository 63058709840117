import React, { useContext } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { SectionPadding } from "../../components/SectionPadding/styles";
import "../DelearInquiry/style.css";
import DealerIntro from "./DealerInquiryIntro";
import { MetaContext } from "../../Store";

const DealerInquiry = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("dealer-enquiry");

      return (
            <>
                  <CustomBreadcrumb
                        subtitle={"DEALER Enquiry"}
                        title={"DEALER Enquiry"}
                  />

                  <SectionPadding>
                        <DealerIntro />
                  </SectionPadding>
            </>
      );
};

export default DealerInquiry;
