import styled from "styled-components";

export const AboutMilestoneBox = styled.div`
  background-image: url("/about/milestone.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export const SingleMilestone = styled.div`
  text-align: center;
  h3 {
    font-size: 20px;
    color: #fff;
    display: block;
    padding: 15px;
    border-bottom: 2px dashed #fff;
    margin-bottom: 20px;
  }
  p {
    color: #fff;
    font-size: 16px;
    padding: 0 40px;
    line-height: 22px;
  }
`;
