import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomHeadline from "../../../components/CustomHeadline";
import DealerForm from "../DealerInquiryForm";

const DealerIntro = () => {
      return (
            <div className="dealer__section">
                  <Container>
                        <Row>
                              <Col lg={12} className="mb-4">
                                    <CustomHeadline title="Become a Dealer of Plumber Pipes" />
                              </Col>
                              <Col md={6} sm={12} className="order-2">
                                    <DealerForm />
                              </Col>
                              <Col md={6} sm={12} className="order-1">
                                    <div className="dealer__image">
                                          <img
                                                src="/images/dealer_enquiry.PNG"
                                                alt="Dealer Enquiry"
                                                className="img-fluid"
                                          />
                                    </div>
                                    <div className="dealer__details">
                                          <p>
                                                Join our extensive network of
                                                dealers and be part of one of
                                                the leading brands in the
                                                plumbing and piping industry. At
                                                Plumber Pipes, we are dedicated
                                                to providing high-quality piping
                                                solutions for both residential
                                                and commercial needs, and we're
                                                looking for committed partners
                                                to help expand our reach.
                                          </p>
                                          <p>
                                                As a Plumber Pipes dealer,
                                                you'll benefit from:
                                          </p>
                                          <img
                                                src="/images/dealer_desc.png"
                                                alt="Dealer Description"
                                                className="img-fluid"
                                          />
                                    </div>
                              </Col>
                        </Row>
                  </Container>
            </div>
      );
};

export default DealerIntro;
