import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./style.css";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { ThumbSwiper } from "./data";

export default function ProductSwiper({ product, images }) {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  {product && (
                        <>
                              <Swiper
                                    spaceBetween={10}
                                    autoplay={true}
                                    loop={true}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper-products"
                              >
                                    {product?.image && (
                                          <SwiperSlide>
                                                <img
                                                      src={product?.image}
                                                      alt={product?.title}
                                                />
                                          </SwiperSlide>
                                    )}
                                    {images &&
                                          images?.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                      <img
                                                            src={item?.image}
                                                            alt={product?.title}
                                                      />
                                                </SwiperSlide>
                                          ))}
                              </Swiper>

                              <ThumbSwiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper-product-small-image"
                              >
                                    {product?.image && (
                                          <SwiperSlide>
                                                <img
                                                      src={product?.image}
                                                      alt={product?.title}
                                                />
                                          </SwiperSlide>
                                    )}
                                    {images &&
                                          images?.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                      <img
                                                            src={item?.image}
                                                            alt={product?.title}
                                                      />
                                                </SwiperSlide>
                                          ))}
                              </ThumbSwiper>
                        </>
                  )}
            </>
      );
}
