import React, { useContext, useEffect, useRef, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { SectionPadding } from "../../components/SectionPadding/styles";
import { Container, Row, Col } from "react-bootstrap";
import CustomHeadline from "../../components/CustomHeadline";
import { socialcontents } from "../Data/socialcontent";
import {
      CloseButton,
      SocialMedia,
      SocialMediaContent,
      SocialMediaDetail,
      SocialPostCard,
      SocialPostContent,
      SocialPostImage,
      SocialTitle,
} from "./style";
import { IoMdClose } from "react-icons/io";
import { MetaContext } from "../../Store";
import { Link, useParams } from "react-router-dom";

const DigitalMedia = () => {
      const { digitalId } = useParams();
      console.log(digitalId);
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("digital-media");
      const facilitySection = useRef(null);
      const [selectedCard, setSelectedCard] = useState(null);

      const handleCloseDetails = () => {
            setSelectedCard(null);
      };

      const scrollToFacility = () => {
            // if (window.innerWidth <= 768) {
            // Adjust breakpoint as needed
            if (facilitySection.current) {
                  const elementPosition =
                        facilitySection.current.getBoundingClientRect().top;
                  const offsetPosition = elementPosition + window.scrollY - 80;

                  window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                  });
            }
            // }
      };

      useEffect(() => {
            if (digitalId) {
                  const selectedContent = socialcontents.find(
                        (item) => item.slug === digitalId
                  );
                  scrollToFacility();
                  setSelectedCard(selectedContent);
            }
      }, [digitalId]);

      return (
            <>
                  <CustomBreadcrumb
                        subtitle={"Digital Media"}
                        title={"Our Social Media"}
                  />
                  <div className="sectionMargin">
                        <div className="sectionMargin position-relative">
                              <SectionPadding className="custom-padding pt-0">
                                    <Container>
                                          <CustomHeadline
                                                title="Content"
                                                textAlign={"center"}
                                          />
                                          <Row className="mt-5">
                                                <Col
                                                      xs={12}
                                                      md={selectedCard ? 8 : 12}
                                                      className="mb-4"
                                                >
                                                      <SocialMedia className="g-4">
                                                            {socialcontents?.map(
                                                                  (
                                                                        data,
                                                                        index
                                                                  ) => (
                                                                        <SocialPostCard
                                                                              key={
                                                                                    index
                                                                              }
                                                                              lg={
                                                                                    selectedCard
                                                                                          ? 6
                                                                                          : 4
                                                                              }
                                                                              className="mb-4"
                                                                        >
                                                                              <Link
                                                                                    className="d-block"
                                                                                    to={`/digital-media/${data?.slug}`}
                                                                              >
                                                                                    <SocialPostImage>
                                                                                          <img
                                                                                                src={
                                                                                                      data.image
                                                                                                }
                                                                                                alt={
                                                                                                      data.title
                                                                                                }
                                                                                                className="img-fluid"
                                                                                          />
                                                                                          <div className="title-overlay">
                                                                                                {
                                                                                                      data.source
                                                                                                }
                                                                                          </div>
                                                                                    </SocialPostImage>
                                                                              </Link>
                                                                        </SocialPostCard>
                                                                  )
                                                            )}
                                                      </SocialMedia>
                                                </Col>

                                                {selectedCard && (
                                                      <Col
                                                            xs={12}
                                                            md={4}
                                                            className="mb-4"
                                                            ref={
                                                                  facilitySection
                                                            }
                                                      >
                                                            <SocialMediaDetail>
                                                                  <CloseButton
                                                                        onClick={
                                                                              handleCloseDetails
                                                                        }
                                                                  >
                                                                        <IoMdClose />
                                                                  </CloseButton>
                                                                  <img
                                                                        src={
                                                                              selectedCard.image
                                                                        }
                                                                        alt={
                                                                              selectedCard.title
                                                                        }
                                                                        className="img-fluid mb-3"
                                                                  />
                                                                  <SocialMediaContent>
                                                                        <p>
                                                                              {
                                                                                    selectedCard.source
                                                                              }
                                                                        </p>
                                                                        <SocialTitle>
                                                                              {
                                                                                    selectedCard.title
                                                                              }
                                                                        </SocialTitle>

                                                                        <SocialPostContent>
                                                                              <div>
                                                                                    <span className="material-symbols-outlined">
                                                                                          favorite
                                                                                    </span>
                                                                                    {
                                                                                          selectedCard.likes
                                                                                    }
                                                                              </div>
                                                                              <div>
                                                                                    <span className="material-symbols-outlined">
                                                                                          calendar_month
                                                                                    </span>
                                                                                    {
                                                                                          selectedCard.date
                                                                                    }
                                                                              </div>
                                                                        </SocialPostContent>
                                                                  </SocialMediaContent>
                                                            </SocialMediaDetail>
                                                      </Col>
                                                )}
                                          </Row>
                                    </Container>
                              </SectionPadding>
                        </div>
                  </div>
            </>
      );
};

export default DigitalMedia;
