// Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";

const CommonSwiper = ({ children, dynamicBreakpoints, space }) => {
      return (
            <>
                  {console.log(space)}
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                        }}
                        loop={true}
                        breakpoints={
                              !dynamicBreakpoints
                                    ? {
                                            640: {
                                                  slidesPerView: 4,
                                                  spaceBetween: space
                                                        ? space
                                                        : 50,
                                            },
                                            768: {
                                                  slidesPerView: 4,
                                                  spaceBetween: space
                                                        ? space
                                                        : 50,
                                            },
                                            1024: {
                                                  slidesPerView: 4,
                                                  spaceBetween: space
                                                        ? space
                                                        : 20,
                                            },
                                      }
                                    : dynamicBreakpoints
                        }
                        modules={[Autoplay]}
                        className="mySwiper"
                  >
                        {children && children}
                  </Swiper>
            </>
      );
};

export default CommonSwiper;
