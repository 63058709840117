import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import CustomAnimate from "../CustomAnimate";
const CustomBreadcrumb = ({ subtitle, title, children }) => {
      return (
            <div className="position-relative">
                  <div className="section-breadcrumb">
                        <div className="breadcrumb-inner">
                              <div className="breadcrumb-overlay"></div>
                              <CustomAnimate>
                                    <div className="breadcrumb-content d-flex justify-content-center align-items-center position-relative flex-column">
                                          <ul className="list-unstyled">
                                                <li className="position-relative">
                                                      <Link
                                                            to="/"
                                                            className="position-relative"
                                                      >
                                                            HOME
                                                      </Link>
                                                </li>
                                                {children && (
                                                      <li
                                                            className={`position-relative ${
                                                                  subtitle
                                                                        ? "beforeList"
                                                                        : ""
                                                            }`}
                                                      >
                                                            {children}
                                                      </li>
                                                )}
                                                {subtitle && (
                                                      <li>{subtitle}</li>
                                                )}
                                          </ul>
                                          <div className="breadcrumb-title font-extraLarge ">
                                                {title}
                                          </div>
                                    </div>
                              </CustomAnimate>
                        </div>
                  </div>
            </div>
      );
};

export default CustomBreadcrumb;
