import React, { useContext, useEffect, useState } from "react";
import FAQ from "./components/FAQ";
import HomeTestomonials from "../Home/components/HomeTestomonials";
import axios from "axios";
import { MetaContext } from "../../Store";

const FAQPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("faqs");

      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);
      const [testimonials, setTestimonials] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setFaqs(response.data.types);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setTestimonials(response.data?.testimonials);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <FAQ faqs={faqs} loading={loading} />
                  <HomeTestomonials
                        testimonials={testimonials}
                        loading={loading}
                  />
                  <div className="marginBottom"></div>
            </>
      );
};

export default FAQPage;
