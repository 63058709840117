import styled from "styled-components";

export const HomeBannerContainer = styled.div`
	position: relative;
	
	.section-pattern {
		background-image: url("/public/assets/pattern02.png");
		position: relative;
		background-repeat: no-repeat;
		background-position: center left;
		background-size: contain;
		max-height: 20%;
		height: 40rem;
		max-width: 40rem;
		width: 30%;
		margin-top: -5.5rem;
	}
	.swiper:hover > .swiper-button-prev,
	.swiper:hover > .swiper-button-next {
		display: flex;
		transition: all 0.3s linear;
	}
	.swiper-button-next,
	.swiper-button-prev {
		color: #fff;
		border-radius: 50%;
		border: 1px solid ${(props) => props.theme.white};
		padding: 15px;
		display: none;
		transition: all 0.3s linear;

		&:after {
			font-size: 13px;
		}
	}
	.swiper-button-prev {
		left: 25px;
	}
	.swiper-button-next {
		right: 25px;
	}
`;
export const HomeBannerImage = styled.div``;
export const VideoContainer = styled.div``;
