import axios from "axios";
import React, { useEffect, useState } from "react";
import "./style.css";
import CommonButton from "../../../../components/CommonButton";
import { Helmet } from "react-helmet";
const CareerDescription = ({ jobs, jobId, detail }) => {
      const [loading, setLoading] = useState(false);
      const [career, setCareer] = useState([]);
      const slug = jobs ? jobs.slug : jobId;

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${slug}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCareer(response.data.job);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [jobId, jobs]);

      return (
            <>
                  {!loading && detail && (
                        <Helmet>
                              <title>
                                    {career?.seo_title || career?.title}
                              </title>
                              {career?.seo_description && (
                                    <meta
                                          name="description"
                                          content={career?.seo_description}
                                    />
                              )}
                              {career?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={career?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <div className="section-description">
                        <div className="desc-heading d-flex justify-content-between align-items-center">
                              <div className="desc-title d-flex font-small ">
                                    <span className="me-2 material-symbols-outlined">
                                          business_center
                                    </span>
                                    {career.title}
                              </div>
                              <CommonButton
                                    slug={`/careers/${slug}/apply-now`}
                                    title={"Appy Now"}
                                    variant={true}
                              />
                        </div>
                        <div className="desc-info ">
                              <table className="table table-borderless">
                                    <thead>
                                          <tr>
                                                <th colSpan={""}>
                                                      <strong>
                                                            Basic Information
                                                      </strong>
                                                </th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          <tr>
                                                <td className="w-3">
                                                      Job title
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.title}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">
                                                      Job Type
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.type}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">
                                                      No of Vacancy
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.vacancy}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">Salary</td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.salary}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">
                                                      Education
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.education}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">
                                                      Experience
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.experience}
                                                </td>
                                          </tr>
                                          <tr>
                                                <td className="w-3">
                                                      Deadline
                                                </td>
                                                <td className="">:</td>
                                                <td className="w-6">
                                                      {career.deadline}
                                                </td>
                                          </tr>
                                    </tbody>
                                    <thead>
                                          <tr colSpan={3}>
                                                <th>
                                                      <strong>
                                                            Other Details
                                                      </strong>
                                                </th>
                                          </tr>
                                    </thead>
                                    <tr>
                                          <td colSpan={3}>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: career.description,
                                                      }}
                                                />
                                          </td>
                                    </tr>
                              </table>
                        </div>
                  </div>
            </>
      );
};

export default CareerDescription;
