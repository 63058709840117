import React from "react";
import {
      Button,
      Col,
      Container,
      Form,
      FormControl,
      Row,
} from "react-bootstrap";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import CustomHeadline from "../../../../components/CustomHeadline";
import "./style.css";
import { ContactForm } from "./style";
import ContactFormList from "./ContactFormList";
const Contact = () => {
      const contactDetails = [
            {
                  id: 1,
                  icon: "bx bx-calendar",
                  contactTitle: "Book Online",
                  contactNumber: "+977 01-5361925",
            },
            {
                  id: 2,
                  icon: "bx bx-envelope",
                  contactTitle: "Email",
                  contactNumber: "info@plumber.com",
            },
            {
                  id: 3,
                  icon: "bx bx-map",
                  contactTitle: "Location",
                  contactNumber: "Kathmandu, Nepal",
            },
            {
                  id: 4,
                  icon: "bx bx-time",
                  contactTitle: "Open Hours",
                  contactNumber: "Mon-Sun 24/7",
            },
      ];

      return (
            <div className="position-relative">
                  <CustomBreadcrumb title={"CONTACT US"} subtitle={"CONTACT"} />
                  <div className="sectionMargin">
                        <Container>
                              <Row>
                                    <Col
                                          md="6"
                                          className="pe-5 d-flex justify-content-center align-items-center flex-column"
                                    >
                                          <CustomHeadline
                                                title={
                                                      "Get in touch! call us now"
                                                }
                                                subtitle={"CONTACT US"}
                                          />
                                          <div className="contact-content">
                                                <div className="font-small my-4 ">
                                                      Reach us to learn more
                                                      about dealers, warranty
                                                      policy and any type of
                                                      customization
                                                </div>
                                                <div className="contact-details">
                                                      <Row className="mt-3">
                                                            {contactDetails.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) =>
                                                                        (item.id ===
                                                                              1 ||
                                                                              item.id ===
                                                                                    2) && (
                                                                              <Col
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                                    md="6"
                                                                                    className="col-6"
                                                                              >
                                                                                    <div className="single-contact__detail">
                                                                                          <div className="contact-icon">
                                                                                                <i
                                                                                                      className={
                                                                                                            item.icon
                                                                                                      }
                                                                                                ></i>
                                                                                          </div>
                                                                                          <div className="contact-info">
                                                                                                <div className="contact-title font-xs mb-0">
                                                                                                      {
                                                                                                            item.contactTitle
                                                                                                      }
                                                                                                </div>
                                                                                                <div className="contact-number font-xxs">
                                                                                                      {
                                                                                                            item.contactNumber
                                                                                                      }
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </Col>
                                                                        )
                                                            )}
                                                      </Row>
                                                      <Row className="mt-4">
                                                            {contactDetails.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) =>
                                                                        (item.id ===
                                                                              3 ||
                                                                              item.id ===
                                                                                    4) && (
                                                                              <Col
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                                    md="6"
                                                                                    className="col-6"
                                                                              >
                                                                                    <div className="single-contact__detail">
                                                                                          <div className="contact-icon">
                                                                                                <i
                                                                                                      className={
                                                                                                            item.icon
                                                                                                      }
                                                                                                ></i>
                                                                                          </div>
                                                                                          <div className="contact-info">
                                                                                                <div className="contact-title mb-0 font-xs ">
                                                                                                      {
                                                                                                            item.contactTitle
                                                                                                      }
                                                                                                </div>
                                                                                                <div className="contact-number font-xxs ">
                                                                                                      {
                                                                                                            item.contactNumber
                                                                                                      }
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </Col>
                                                                        )
                                                            )}
                                                      </Row>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col md="6">
                                          <ContactForm className="contact-form ">
                                                <ContactFormList />
                                          </ContactForm>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </div>
      );
};

export default Contact;
