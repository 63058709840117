import styled from "styled-components";

export const FeatureItem = styled.div`
      padding: 40px 20px;
      text-align: center;
      color: #fff;
      position: relative;
      margin: 10px 0;
      min-height: 220px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      &.border-radius-last {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
      }

      &.border-radius-first {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
      }

      h5 {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 15px;
      }

      h3 {
            font-size: 22px;
            font-weight: 500;
      }

      @media screen and (max-width: 568px) {
            padding: 30px 20px;
            min-height: auto;
            align-items: center;

            .arrow-icon {
                  position: relative;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  left: 0;
                  bottom: auto;
                  height: auto;

                  span {
                        line-height: 1;
                  }
            }

            h5 {
                  font-size: 14px;
            }

            h3 {
                  font-size: 18px;
            }
      }
`;
