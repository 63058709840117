import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
      FacebookIcon,
      InstagramIcon,
      TwitterIcon,
} from "../../reuseables/icons";
import "./style.css";
import { WaterDrop2 } from "../../Home/components/HomeTestomonials/style";
import SubscriberForm from "./SubscriberForm";
import styled from "styled-components";
import CategorySlider from "./CategorySlider";

// import required modules

const FooterBanner = styled.div`
      height: 140px;
      margin-top: 14rem;
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
            rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

      .background__image {
            height: 100%;
            width: 100%;
            object-position: bottom;
            object-fit: cover;
      }

      .footer__category-list {
            height: 220px;
            z-index: 99;
            position: absolute;
            bottom: 0;
            right: 0px;

            .mySwiper {
                  height: 100%;
                  width: 100%;
                  max-width: 600px;
                  margin: auto;
            }

            .swiper-slide {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;

                  .slider__image {
                        width: 100%;
                        border-radius: 5px;
                        padding: 20px;
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                        background-color: ${(props) => props.theme.white};
                  }
            }

            .swiper-slide img {
                  height: 100%;
                  width: 100%;
                  object-position: center;
                  transition: transform 0.3s ease;
                  object-fit: contain;
            }

            .swiper-slide img:hover {
                  transform: scale(1.05);
            }
      }

      .giri__image {
            height: 380px;
            z-index: 99;
            position: absolute;
            bottom: 0;
            left: 0;

            img {
                  height: 100%;
                  width: 100%;
                  object-position: bottom;
                  object-fit: contain;
            }
      }

      @media screen and (max-width: 568px) {
            height: 100px;
            margin-top: 2rem;
            .footer__category-list {
                  height: 120px;

                  .mySwiper {
                        max-width: 220px;

                        .swiper-slide {
                              .slider__image {
                                    padding: 10px;
                                    height: 80px;
                              }
                        }
                  }
            }

            .giri__image {
                  left: -20px;
            }
      }
`;

const Footer = () => {
      const currentYear = new Date().getFullYear();

      return (
            <>
                  <FooterBanner>
                        <img
                              className="background__image"
                              src="/footer/background.png"
                              alt=""
                        />
                        <Container className="position-relative">
                              <div className="giri__image">
                                    <img
                                          className=""
                                          src="/footer/Giri.png"
                                          alt=""
                                    />
                              </div>
                              <div className="footer__category-list">
                                    <CategorySlider />
                              </div>
                        </Container>
                  </FooterBanner>
                  <footer className="app__footer  ">
                        <div className="water-drop1">
                              <img
                                    src="/banners/waterdrop2.png"
                                    alt=""
                                    className="img-fluid"
                              />
                        </div>
                        <WaterDrop2>
                              <img
                                    src="/banners/waterdrop1.png"
                                    alt=""
                                    className="img-fluid"
                              />
                        </WaterDrop2>
                        <div className="footer__container">
                              <Container>
                                    <Row className="g-4">
                                          <Col md={"4"}>
                                                <div className="app__logo">
                                                      <img
                                                            src="/banners/plumber.png"
                                                            alt="Plumber Pipe Pvt. Ltd."
                                                            className="img-fluid mb-3"
                                                      />
                                                      <div
                                                            className="customPara"
                                                            style={{
                                                                  color: "#555",
                                                                  textAlign:
                                                                        "justify",
                                                            }}
                                                      >
                                                            <p>
                                                                  Plumber Pipes
                                                                  and Fittings,
                                                                  manufactured
                                                                  by Jai
                                                                  Bageshwari
                                                                  Roto Moulding
                                                                  Industries,
                                                                  and are made
                                                                  from the
                                                                  speciality
                                                                  plastic,
                                                                  chemically
                                                                  known as CPVC
                                                                  and a maxium
                                                                  service
                                                                  temperature.
                                                            </p>
                                                      </div>
                                                </div>
                                                <div className="footer-meta"></div>
                                          </Col>

                                          <Col>
                                                <h3 className="footer-title mb-3">
                                                      Quick Links
                                                </h3>
                                                <ul className="footer-ul list-unstyled">
                                                      <li className="footer-li">
                                                            <Link to="/faq">
                                                                  FAQs
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/careers">
                                                                  Careers
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/blogs">
                                                                  Blog & News
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/privacy-policy">
                                                                  Privacy Policy
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link
                                                                  to={
                                                                        "terms-and-condition"
                                                                  }
                                                            >
                                                                  Terms &
                                                                  Condition
                                                            </Link>
                                                      </li>
                                                </ul>
                                          </Col>
                                          <Col>
                                                <h3 className="footer-title mb-3">
                                                      Useful Links
                                                </h3>
                                                <ul className="footer-ul list-unstyled">
                                                      <li className="footer-li">
                                                            <Link to="/csr">
                                                                  CSR
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/gallery">
                                                                  Gallery
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/catalogue">
                                                                  Catalogue
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/dealer-location">
                                                                  Dealers
                                                            </Link>
                                                      </li>
                                                      <li className="footer-li">
                                                            <Link to="/dealer-enquiry">
                                                                  Dealer Enquiry
                                                            </Link>
                                                      </li>
                                                </ul>
                                          </Col>
                                          <Col lg={3} className="col-12">
                                                <h3 className="footer-title mb-3">
                                                      Contact Us
                                                </h3>
                                                <div className="footer-icons">
                                                      <Link
                                                            to={
                                                                  "https://www.facebook.com/PlumberPipesNepal"
                                                            }
                                                            target="_blank"
                                                      >
                                                            <FacebookIcon />
                                                      </Link>
                                                      <Link
                                                            target="_blank"
                                                            to="https://www.instagram.com/plumberpipes"
                                                      >
                                                            <InstagramIcon />
                                                      </Link>
                                                      <Link to="">
                                                            <TwitterIcon />
                                                      </Link>
                                                </div>
                                                <SubscriberForm />
                                          </Col>
                                    </Row>
                              </Container>
                        </div>

                        <div className="footer-copyright mt-5">
                              <Container>
                                    <div className="copyright-inner">
                                          <div className="">
                                                ©️ {currentYear}{" "}
                                                <span>Plumber Pipes</span> |{" "}
                                                <span>
                                                      Jai Bageshwari Roto
                                                      Moulding Industries
                                                </span>
                                                . All Rights Reserved.
                                          </div>
                                          <div className="">
                                                Powered By{" "}
                                                <Link
                                                      target="_blank"
                                                      to="https://infinityinfosys.com/"
                                                >
                                                      Infinity Infosys
                                                </Link>
                                          </div>
                                    </div>
                              </Container>
                        </div>

                        <div className="footer__background">
                              <img
                                    src="/banners/footer.png"
                                    alt=""
                                    className="img-fluid"
                              />
                        </div>
                  </footer>
            </>
      );
};

export default Footer;
