import styled from "styled-components";

export const Testimonial = styled.div`
      margin-top: 5rem;
      position: relative;
`;

export const WaterDrop1 = styled.div`
      position: absolute;
      top: 0px;
      left: 0px;
      width: 200px;
      top: -10%;
`;

export const WaterDrop2 = styled.div`
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 200px;
      z-index: 10;
`;

export const TestimonialContent = styled.div`
      display: flex;
      align-items: center;
      height: 100%;
      border-radius: 10px;
      padding: 4rem 30px;
      flex-direction: column;
      justify-content: center;
      background-color: var(--primary-color);
`;

export const SectionTestimonials = styled.div`
      background-size: contain;
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;

      /* &::after {
            position: absolute;
            content: "";
            background-color: var(--primary-color);
            left: 0%;
            top: 0%;
            width: 40%;
            height: 100%;
            z-index: -10;
            border-radius: 0px 15px 15px 0px;
      } */

      @media screen and (max-width: 568px) {
            padding-top: 3rem;
            padding-bottom: 3rem;

            .main {
                  text-align: center;
            }

            .right {
                  display: none;
            }

            p {
                  text-align: center !important;
            }

            &::after {
                  width: 100%;
            }
      }
`;
