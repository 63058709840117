import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation styles

import {
      ContainerTimeline,
      HistoryTitle,
      SwiperContainerWrapper,
} from "./style";

const HistorySwiper = ({ milestones }) => {
      const [activeIndex, setActiveIndex] = useState(0);

      return (
            <ContainerTimeline>
                  <SwiperContainerWrapper className="swiper-container-wrapper swiper-container-wrapper--timeline">
                        {/* Timeline */}
                        <ul className="swiper-pagination-custom">
                              {milestones?.map((item, index) => (
                                    <li
                                          key={index}
                                          className={`swiper-pagination-switch ${
                                                index === 0 ? "first" : ""
                                          } ${
                                                index === activeIndex
                                                      ? "active"
                                                      : ""
                                          }`}
                                          onClick={() => setActiveIndex(index)}
                                    >
                                          <span className="switch-title">
                                                {item?.year}
                                          </span>
                                    </li>
                              ))}
                        </ul>
                        {/* Progressbar */}
                        <div className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal"></div>
                  </SwiperContainerWrapper>
                  <HistoryTitle>{milestones[activeIndex]?.title}</HistoryTitle>
            </ContainerTimeline>
      );
};

export default HistorySwiper;
