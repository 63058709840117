import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./style.css";
import CommonButton from "../../../../components/CommonButton";
import SidePanel from "./components/SidePanel";
import { Link } from "react-router-dom";

const BlogList = ({ blogs, categories, loading }) => {
      return (
            <div className="blogs-wrapper sectionMargin">
                  <div className="single-blog">
                        <Row>
                              <Col lg="8">
                                    {!loading ? (
                                          blogs.map((blog) => (
                                                <div className="blog-box">
                                                      <Col
                                                            lg="12"
                                                            className="mb-5"
                                                      >
                                                            <Row className="g-0">
                                                                  <Col lg="6">
                                                                        <div className="single-blog__img">
                                                                              <Link
                                                                                    to={`/blogs/${blog.slug}`}
                                                                              >
                                                                                    <img
                                                                                          src={
                                                                                                blog?.image
                                                                                          }
                                                                                          alt={
                                                                                                blog?.title
                                                                                          }
                                                                                          className="img-fluid"
                                                                                    />
                                                                              </Link>
                                                                        </div>
                                                                  </Col>
                                                                  <Col lg="6">
                                                                        <div className="single-blog__content">
                                                                              <div className="d-flex gap-3">
                                                                                    <div className="blog-meta font-xs">
                                                                                          <i className="bx bx-calendar me-1"></i>
                                                                                          <span className="blog-date font-xs">
                                                                                                {
                                                                                                      blog.date
                                                                                                }
                                                                                          </span>
                                                                                    </div>
                                                                                    <div className="blog-meta d-flex align-items-center">
                                                                                          <i className="bx bx-user me-1"></i>
                                                                                          <span className="blog-author  font-xs ms-0 me-3">
                                                                                                Plumber
                                                                                                Pipes
                                                                                          </span>
                                                                                    </div>
                                                                              </div>
                                                                              <div className="blog-body ">
                                                                                    <div className="blog-title">
                                                                                          {
                                                                                                blog?.title
                                                                                          }
                                                                                    </div>
                                                                                    <div className="font-extraSmall text-muted mb-2">
                                                                                          {
                                                                                                blog?.summary
                                                                                          }
                                                                                    </div>
                                                                                    <div className="d-flex mt-3 justify-content-between align-items-center">
                                                                                          <CommonButton
                                                                                                slug={`/blogs/${blog?.slug}`}
                                                                                                title={
                                                                                                      "Read More"
                                                                                                }
                                                                                          />
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </Col>
                                                            </Row>
                                                      </Col>
                                                </div>
                                          ))
                                    ) : (
                                          <div className="text-center py-5">
                                                <Spinner />
                                          </div>
                                    )}
                              </Col>
                              <Col lg="4">
                                    <SidePanel
                                          blog={false}
                                          categories={categories}
                                    />
                              </Col>
                        </Row>
                  </div>
            </div>
      );
};

export default BlogList;
