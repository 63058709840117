import styled from "styled-components";

export const MobileNavContainer = styled.div`
      display: none;
      @media screen and (max-width: 1200px) {
            display: block;
      }
`;

export const MobileMenuButton = styled.div`
      padding: 0;
      font-size: 32px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
            color: ${(props) => props.theme.white};
            font-size: 32px;
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
`;
