import { Link, useParams } from "react-router-dom";
import "./style.css";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import SidePanel from "../BlogList/components/SidePanel";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const SingleBlogDetail = () => {
      const { blogId } = useParams();
      const [loading, setLoading] = useState(false);
      const [blog, setBlog] = useState([]);
      const [blogs, setBlogs] = useState([]);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setBlog(response.data.blog);
                        setBlogs(response.data.blogs);
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [blogId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{blog?.seo_title || blog?.title}</title>
                              {blog?.seo_description && (
                                    <meta
                                          name="description"
                                          content={blog?.seo_description}
                                    />
                              )}
                              {blog?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={blog?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}

                  <CustomBreadcrumb title={blog?.title}>
                        <Link to={"/blogs"}>BLOG</Link>
                  </CustomBreadcrumb>
                  <div className="sectionMargin marginBottom ">
                        <Container>
                              <Row>
                                    <Col lg="8">
                                          <div className="blog__detail">
                                                <div className="blog__img">
                                                      <img
                                                            src={blog?.image}
                                                            alt={blog?.title}
                                                            className="img-fluid"
                                                      />
                                                </div>
                                                <div className="blog__meta my-2">
                                                      <span className="me-4">
                                                            <i className="bx bx-user me-2"></i>
                                                            Plumber Pipes
                                                      </span>
                                                      <span>
                                                            <i className="bx bx-calendar me-2"></i>
                                                            {blog?.date}
                                                      </span>
                                                </div>
                                                <h4>{blog?.title}</h4>
                                                <div
                                                      className="blog__desc  my-4"
                                                      dangerouslySetInnerHTML={{
                                                            __html: blog?.description,
                                                      }}
                                                ></div>
                                          </div>
                                    </Col>
                                    <Col lg="4">
                                          <SidePanel
                                                loading={loading}
                                                blogs={blogs}
                                                categories={categories}
                                                blog={true}
                                          />
                                    </Col>
                              </Row>
                        </Container>

                        <div></div>
                  </div>
            </>
      );
};

export default SingleBlogDetail;
