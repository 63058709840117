import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FeaturedProductSwiper({ products }) {
      return (
            <Swiper
                  slidesPerView={4}
                  spaceBetween={20}
                  pagination={false}
                  modules={[Pagination]}
                  className="mySwiper sectionMargin"
                  breakpoints={{
                        320: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                        },
                        576: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                        },
                        768: {
                              slidesPerView: 3,
                              spaceBetween: 20,
                        },
                        1024: {
                              slidesPerView: 4,
                              spaceBetween: 25,
                        },
                  }}
            >
                  {products?.map((product) => (
                        <SwiperSlide key={product.id}>
                              <div className="product__content p-0">
                                    <Card className="product__item">
                                          <Link
                                                title={product?.title}
                                                to={`/products/${product?.slug}`}
                                          >
                                                {product?.sale_status === 1 && (
                                                      <span
                                                            className={`product-status sale`}
                                                      >
                                                            Sale
                                                      </span>
                                                )}
                                                {product?.stock_status ===
                                                      1 && (
                                                      <span className="stock-status">
                                                            In Stock
                                                      </span>
                                                )}
                                                <figure className="article-img">
                                                      <img
                                                            src={product?.image}
                                                            alt={product?.title}
                                                      />
                                                </figure>
                                                <h2 className="titles product-title">
                                                      {product?.title}
                                                </h2>
                                                {/* <div className="price">
                                                      {product?.sale_price !==
                                                      0 ? (
                                                            <>
                                                                  <span className="current-price">
                                                                        NRs.{" "}
                                                                        {
                                                                              product?.price
                                                                        }
                                                                  </span>
                                                                  <span className="old-price">
                                                                        NRs.{" "}
                                                                        {
                                                                              product?.sale_price
                                                                        }
                                                                  </span>
                                                            </>
                                                      ) : (
                                                            <>
                                                                  <span className="current-price">
                                                                        NRs.{" "}
                                                                        {
                                                                              product?.price
                                                                        }
                                                                  </span>
                                                            </>
                                                      )}
                                                </div> */}
                                          </Link>
                                          <Link
                                                to={`/products/${product?.slug}`}
                                          >
                                                <Button
                                                      title="Show More Details"
                                                      className="btn rounded-btn text-btn mt-0"
                                                >
                                                      Explore Now
                                                </Button>
                                          </Link>
                                    </Card>
                              </div>
                        </SwiperSlide>
                  ))}
            </Swiper>
      );
}
