import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GalleryHover, SingleGallery } from "../../styles";
import { Link } from "react-router-dom";

const PhotoGallery = ({ photos }) => {
      return (
            <>
                  <Container className="mt-5">
                        <Row>
                              {photos?.map((photo, i) => (
                                    <Col lg="3" key={i}>
                                          <SingleGallery>
                                                <Link
                                                      to={`photo-gallery/${photo?.slug}`}
                                                >
                                                      <img
                                                            src={photo?.image}
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                      <GalleryHover className="d-flex align-items-center">
                                                            <div className="">
                                                                  <span className="material-symbols-outlined text-center fs-3">
                                                                        visibility
                                                                  </span>
                                                            </div>
                                                            <h5>
                                                                  {photo?.title}
                                                                  <span className="mt-1">
                                                                        Photos:{" "}
                                                                        {
                                                                              photo
                                                                                    ?.images
                                                                                    .length
                                                                        }
                                                                  </span>
                                                            </h5>
                                                      </GalleryHover>
                                                </Link>
                                          </SingleGallery>
                                    </Col>
                              ))}
                        </Row>
                  </Container>
            </>
      );
};

export default PhotoGallery;
