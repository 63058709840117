import styled from "styled-components";

export const CommonHeadlineContainer = styled.h4`
  span {
    font-size: 24px;
    background: ${(props) =>
      props.gradient
        ? "linear-gradient(to left, rgb(14, 149, 179) 1%, rgb(0, 20, 94))"
        : "none"};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
