import { Row } from "react-bootstrap";
import styled from "styled-components";

export const CatalogueRow = styled(Row)``;
export const SingleCatalogue = styled.div`
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 15px;
`;
export const CatalogueImg = styled.div`
      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
      }
`;
export const CatalogueContent = styled.div`
      padding: 20px 15px;
      /* background-color: #ddd; */

      a {
            color: var(--primary-color);
            display: inline-block;
      }
      h4 {
            font-size: 18px;
            font-weight: 400;
            margin: 0;
      }
      i {
            font-size: 18px;
      }
`;
