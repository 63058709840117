export const socialcontents = [
      {
            id: 1,
            title: "Happy World Plumbing Day. Let's take a moment to appreciate the importance of clean water and proper sanitation in our lives. Thank you to all the plumbers and professionals who work tirelessly to ensure safe water systems for everyone.",
            source: "instagram",
            image: "/banners/post1.jpg",
            slug: "happy-world-plumbing-2024",
            likes: "24",
            date: "March 11, 2024",
      },
      {
            id: 2,
            title: "जापानी कम्पाउन्डबाट बनाइएका प्लम्बर पाइपहरूले उच्च गुणस्तरका सामग्रीहरू र सजिलो जडानका डिजाइनसहित ५० वर्षभन्दा बढी लामो आयु प्रदान गर्नका लागि विशेष रूपमा निर्मित गरिएको छ।",
            source: "Facebook",
            image: "/banners/post2.jpg",
            slug: "jhapani-kapamawadwad",
            likes: "30",
            date: "March 10, 2024",
      },
      {
            id: 3,
            title: "Plumber Pipes invites you all to experience the forefront of architectural excellence at Nepal's largest Buildcon International Expo & discover the latest in construction and design & engineering.Date: Feb 1 to Feb 4, 2024 (10 AM to 5 PM) Location: Bhrikuti Mandap, Kathmandu. Stall Location : Hall-B(Main Hall), Booth R-5 (Near To Exit)",
            source: "Facebook",
            image: "/banners/post3.jpg",
            slug: "plumber-pipes-invites",
            likes: "34",
            date: "Feb 1, 2024",
      },
      {
            id: 4,
            title: "Sealing the Deal: Plumber's UPVC pipes with the innovative Blue Lip Seal Ring, ensuring a flawless 100% leak-proof solution.Say goodbye to worries and hello to reliable plumbing!",
            source: "instagram",
            image: "/banners/post4.jpg",
            slug: "sealing-the-deal",
            likes: "24",
            date: "August 15, 2023",
      },
      {
            id: 5,
            title: "Plumber Premium Bath and Fittings Create masterpieces for your bathroom",
            source: "Facebook",
            image: "/banners/post5.jpg",
            slug: "plumber-premium-bath-and-fittings",
            likes: "30",
            date: "March 20, 2023",
      },
      {
            id: 3,
            title: "गुणस्तर नै हो, भरोसाको आधार नेपाल गुणस्तर चिन्हबाट प्रमाणीत प्लम्बर ब्राण्डका पि.भि.सि. पाइप्स् तथा फिटिङ हरु । Plumber uPVC Plumbing System, Perfect for water distribution and wastewater management.",
            source: "Facebook",
            image: "/banners/post6.jpg",
            slug: "gunasanar-ni-ho-bharoshako-aadhar",
            likes: "34",
            date: "Feb 1, 2024",
      },
];
