import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import HistorySwiper from "./components/HistorySwiper";
import axios from "axios";

const HomeHistory = () => {
      const [loading, setLoading] = useState(false);
      const [milestones, setMilestones] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/milestones`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setMilestones(response.data.milestones);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <div className="home-history">
                  <Container>
                        <Row className="g-0 align-items-center">
                              <Col md="5">
                                    <div className="history-image">
                                          <img
                                                src="/banners/demo.png"
                                                alt="History"
                                          />
                                    </div>
                              </Col>

                              <Col md="7">
                                    <div className="history-content">
                                          <h4 className="our-story">
                                                Our Story
                                          </h4>
                                          <h2 className="building-better">
                                                Building better, together.
                                          </h2>
                                          <h3 className="history-title">
                                                Pioneers of CPVC Piping system
                                                in Nepal
                                          </h3>
                                          <p className="history-description">
                                                After much persuasion, the trade
                                                and consumer communities started
                                                transitioning from the GI pipes
                                                to the far superior CPVC piping
                                                system. As a result, the home
                                                segment was seen to gradually
                                                adapt to the new and more
                                                efficient piping system.
                                          </p>

                                          <HistorySwiper
                                                milestones={milestones}
                                          />
                                    </div>
                              </Col>
                        </Row>
                  </Container>
            </div>
      );
};

export default HomeHistory;
