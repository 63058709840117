import React, { useEffect, useRef, useState } from "react";
import Mmenu from "mmenu-js";
import "mmenu-js/dist/mmenu.css";
import { Link } from "react-router-dom";
import axios from "axios";
const MobileMenuItem = ({ category, setShow }) => {
      const menuRef = useRef(null);

      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            if (!menuRef.current) {
                  menuRef.current = new Mmenu("#menu", {
                        offCanvas: {
                              use: false,
                        },
                        counters: {
                              add: true,
                        },
                        setSelected: {
                              hover: true,
                        },
                        navbars: [
                              {
                                    position: "top",
                                    use: false,
                                    content: ["searchfield"],
                              },
                        ],
                  });
            }
      }, []);

      return (
            <>
                  <nav id="menu">
                        <ul>
                              {!loading
                                    ? category?.map((cat, index) =>
                                            cat?.children ? (
                                                  <li>
                                                        <span>{cat.title}</span>
                                                        <ul>
                                                              {categories?.map(
                                                                    (
                                                                          sub,
                                                                          index
                                                                    ) => (
                                                                          <li
                                                                                key={
                                                                                      index
                                                                                }
                                                                          >
                                                                                <Link
                                                                                      to={`/products/${sub.slug}/category`}
                                                                                      onClick={() =>
                                                                                            setShow()
                                                                                      }
                                                                                >
                                                                                      {
                                                                                            sub.title
                                                                                      }
                                                                                </Link>
                                                                                {sub
                                                                                      .subCategory
                                                                                      ?.length >
                                                                                      0 && (
                                                                                      <ul>
                                                                                            {sub?.subCategory?.map(
                                                                                                  (
                                                                                                        item,
                                                                                                        key
                                                                                                  ) => (
                                                                                                        <li
                                                                                                              key={
                                                                                                                    key
                                                                                                              }
                                                                                                        >
                                                                                                              <Link
                                                                                                                    to={
                                                                                                                          item.slug
                                                                                                                    }
                                                                                                                    onClick={() =>
                                                                                                                          setShow()
                                                                                                                    }
                                                                                                              >
                                                                                                                    {
                                                                                                                          item.title
                                                                                                                    }
                                                                                                              </Link>
                                                                                                        </li>
                                                                                                  )
                                                                                            )}
                                                                                      </ul>
                                                                                )}
                                                                          </li>
                                                                    )
                                                              )}
                                                        </ul>
                                                  </li>
                                            ) : (
                                                  <li key={index}>
                                                        <Link
                                                              to={cat.slug}
                                                              onClick={() =>
                                                                    setShow()
                                                              }
                                                        >
                                                              {cat.title}
                                                        </Link>
                                                  </li>
                                            )
                                      )
                                    : null}
                        </ul>
                  </nav>
            </>
      );
};

export default MobileMenuItem;
