import styled from "styled-components";

export const CommonButtonContainer = styled.button`
      border: 0;
      background-color: var(--primary-color);
      padding: 0;
      width: 160px;
      height: 38px;
      color: #fff;
      border-radius: 5px;

      a {
            display: inline-block;
            color: ${(props) =>
                  props.variant ? "var(--primary-color)" : "#fff"};
            border: 1px solid var(--primary-color);
            padding: 6px 20px;
            border-radius: 5px;
            transition: 0.3s ease-in-out;
            text-align: center;
            width: 100%;
            height: 100%;

            &:hover {
                  background-color: ${(props) =>
                        props.variant
                              ? "var(--primary-color)"
                              : "#fff"} !important;
                  color: ${(props) =>
                        props.variant
                              ? "#fff"
                              : "var(--primary-color)"} !important;
                  transition: 0.3s ease-in-out;
            }
      }
      .spinner-border {
            width: 1rem;
            height: 1rem;
            border-width: 1px;
      }
`;
