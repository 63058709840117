import React from "react";
import { Link } from "react-router-dom";
import { CommonButtonContainer } from "./styles";
import { Spinner } from "react-bootstrap";

const CommonButton = ({
      loading,
      onClick,
      slug,
      title,
      variant,
      classes,
      type,
}) => {
      const variant1 = {
            backgroundColor: "var(--primary-color)",
            color: "#fff",
      };
      return (
            <>
                  <CommonButtonContainer
                        className={classes}
                        variant={variant}
                        onClick={onClick}
                        type={type || "button"}
                  >
                        {type === "submit" ? (
                              !loading ? (
                                    title
                              ) : (
                                    <Spinner />
                              )
                        ) : (
                              <Link to={slug} style={variant1}>
                                    {!loading ? title : <Spinner />}
                              </Link>
                        )}
                  </CommonButtonContainer>
            </>
      );
};

export default CommonButton;
