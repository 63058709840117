import styled from "styled-components";

export const Input = styled.input`
  font-size: 15px;
  padding: 13px 15px;
  background: #f0f0f0;
  border: 0;
  color: #5e5c5c;
  width: 100%;
  &:focus {
    box-shadow: none;
    background: #fce0b8;
    color: #222;
  }
  &:focus-visible {
    outline: 0;
  }
`;
export const Textarea = styled.textarea`
  font-size: 15px;
  padding: 13px 15px;
  background: #f0f0f0;
  border: 0;
  color: #5e5c5c;
  width: 100%;
  &:focus {
    border: none;
    box-shadow: none;
    background: #fce0b8;
    color: #222;
  }
  &:focus-visible {
    outline: 0;
  }
`;
