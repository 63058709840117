import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import CustomHeadline from "../../components/CustomHeadline";
import { Container } from "react-bootstrap";
import BlogList from "../BlogPage/components/BlogList";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogCategoryPage = () => {
      const { categoryId } = useParams();
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [categories, setCategories] = useState([]);
      const [category, setCategory] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${categoryId}/category`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlogs(response.data.blogs);
                              setCategory(response.data.category);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {category?.seo_title || `Blog by ${category?.title}`}
                              </title>
                              {category?.seo_description && (
                                    <meta
                                          name="description"
                                          content={category?.seo_description}
                                    />
                              )}
                              {category?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb
                        title={`Category by ${category?.title}`}
                        subtitle={`BLOG`}
                  />
                  <div className="sectionMargin marginBottom">
                        <CustomHeadline
                              subtitle={"Blogs & News"}
                              title={`Latest  Posts by ${category?.title}`}
                              textAlign={"center"}
                        />
                        <Container>
                              <BlogList
                                    loading={loading}
                                    blogs={blogs}
                                    categories={categories}
                              />
                        </Container>
                  </div>
            </>
      );
};

export default BlogCategoryPage;
