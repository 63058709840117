import React, { useState } from "react";
import { SendIcon } from "../../reuseables/icons";
import { Button, Form, FormControl, Spinner } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

const SubscriberForm = () => {
      const [loading, setLoading] = useState(false);
      const [email, setEmail] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/subscribers`,
                        {
                              email: email,
                        },
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setEmail("");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm} className="footer-input mt-3">
                        <FormControl
                              placeholder="Email Address"
                              type="email"
                              value={email}
                              onChange={(event) => setEmail(event.target.value)}
                        />
                        <button type="submit">
                              {loading ? <Spinner /> : <SendIcon />}
                        </button>
                  </Form>
            </>
      );
};

export default SubscriberForm;
