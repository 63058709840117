import React from "react";
import "./style.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
import {
      HomeBannerContainer,
      HomeBannerImage,
} from "../../../../components/frontend/Home/components/HomeBanner/styles";
const HomeSection = () => {
      return (
            <HomeBannerContainer>
                  <div className="home-section">
                        <h1 className="d-none">
                              Plumber Pipes Nepal Pvt. Ltd. || Best upvc, cpvc,
                              pvc , bath fittings and tank solution in Nepal
                        </h1>
                        <Swiper
                              slidesPerView={1}
                              spaceBetween={30}
                              centeredSlides={true}
                              autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                              }}
                              loop={true}
                              pagination={{
                                    clickable: true,
                              }}
                              navigation={true}
                              modules={[Autoplay, Navigation, EffectFade]}
                              className="mySwiper"
                        >
                              <SwiperSlide>
                                    <HomeBannerImage>
                                          <img src="banners/Giri.png" alt="" />
                                    </HomeBannerImage>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <HomeBannerImage>
                                          <img src="banners/Giri.jpg" alt="" />
                                    </HomeBannerImage>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <HomeBannerImage>
                                          <img src="banners/banner.png" alt="" />
                                    </HomeBannerImage>
                              </SwiperSlide>
                        </Swiper>
                  </div>
                  <div className="scroll-down">
                        <span></span>
                        <span></span>
                        <span></span>
                  </div>
            </HomeBannerContainer>
      );
};

export default HomeSection;
