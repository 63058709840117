import React from "react";
import { Col, Spinner } from "react-bootstrap";
import { PolicyContainer } from "./style";

const PolicyLayout = ({ policy, loading }) => {
      return (
            <PolicyContainer>
                  {loading ? (
                        <Spinner
                              animation="border"
                              role="status"
                              variant="secondary"
                              className=""
                        >
                              <span className="visually-hidden">
                                    Loading...
                              </span>
                        </Spinner>
                  ) : (
                        <Col lg="12">
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: policy.description,
                                    }}
                              ></div>
                        </Col>
                  )}
            </PolicyContainer>
      );
};

export default PolicyLayout;
