import styled from "styled-components";

export const CustomButtonContainer = styled.div`
	a {
		display: inline-block;
		padding: 10px;
		font-size: 16px;
		background: ${(props) =>
			props.variant ? "rgb(255, 255, 255)" : props.variant};
		border-color: ${(props) =>
			props.variant ? "rgb(255, 255, 255)" : props.variant};
		color: rgb(1, 30, 101);
		border: 1px solid rgb(255, 255, 255);
		&:hover {
			background: ${(props) =>
				props.variant
					? " linear-gradient(to left, rgb(14, 149, 179) 1%, rgb(0, 20, 94))"
					: props.variant};
			border: 1px solid rgb(255, 255, 255);
			color: #fff;
			transition: all ease-in-out 0.4s;
		}
	}
`;
