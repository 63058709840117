import { Row } from "react-bootstrap";
import styled from "styled-components";

export const ProductRow = styled(Row)`
	overflow: hidden;
`;
export const ProductContent = styled.div``;
export const ProductImg = styled.div`
	width: 100%;
	height: 500px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
