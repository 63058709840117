import React from "react";
import SingleCareer from "./components/SingleCareer";
// import "./style.css";
import { CommonCategoryBox } from "../../../../styles/components/CommonLayout";
const CareerJoblists = ({ jobs }) => {
      return (
            <CommonCategoryBox>
                  <ul className="list-unstyled d-block mb-0">
                        {jobs?.map((career, index) => (
                              <SingleCareer jobs={career} key={index} />
                        ))}
                  </ul>
            </CommonCategoryBox>
      );
};

export default CareerJoblists;
