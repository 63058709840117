import styled from "styled-components";

export const CommonHeadingContainer = styled.div`
	text-align: left;
	width: 100%;
`;
export const CommonHeadingTitle = styled.div`
	position: relative;
	text-transform: uppercase;
	font-size: 22px;

	text-align: left;
	font-family: "Roboto", sans-serif;
	letter-spacing: 0.2rem;
	line-height: 38px;
	color: ${(props) => props.theme.primary};
`;

export const CommonHeadingLine = styled.span`
	width: 100%;
	border-bottom: 2px solid var(--primary-color);
	height: 2px;
	position: relative;
`;

export const CommonHeadingSubTitle = styled.div`
	width: 100%;
`;
