import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon,
      MobileFilter,
} from "./style";

const ProductList = ({
      products,
      loading,
      selectedCategories,
      category,
      handleChangeCategory,
}) => {
      const [limit, setLimit] = useState(21);

      const handleScroll = () => {
            if (
                  window.innerHeight + document.documentElement.scrollTop >=
                  document.documentElement.offsetHeight - 150
            ) {
                  setLimit((prevLimit) => prevLimit + 21); // Load 20 more flights
            }
      };

      useEffect(() => {
            window.addEventListener("scroll", handleScroll);
            return () => {
                  // Cleanup the event listener on component unmount
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);

      return (
            <>
                  <div className="filter__container">
                        <div className="desktop__filter">
                              {products?.length || 0} products found{" "}
                              {category.title && <>on {category.title}</>}
                        </div>
                  </div>

                  <MobileFilter className="mb-3">
                        {products?.length || 0} products found{" "}
                        {category.title && <>on {category.title}</>}
                  </MobileFilter>

                  <CategoryListFilter>
                        {selectedCategories.length > 0 &&
                              selectedCategories.map((item, index) => (
                                    <li key={index}>
                                          <CategoryListItem>
                                                {item.title}
                                                <CategoryListRemove
                                                      onClick={() =>
                                                            handleChangeCategory(
                                                                  item.id,
                                                                  item.type
                                                            )
                                                      }
                                                >
                                                      <CategoryListRemoveIcon>
                                                            <i className="bx bx-x"></i>
                                                      </CategoryListRemoveIcon>
                                                </CategoryListRemove>
                                          </CategoryListItem>
                                    </li>
                              ))}
                  </CategoryListFilter>

                  <Row>
                        {!loading ? (
                              products
                                    ?.slice(0, limit)
                                    ?.map((product, index) => (
                                          <Col
                                                key={index}
                                                md={4}
                                                className="product__content"
                                          >
                                                <Card className="product__item">
                                                      <Link
                                                            title={
                                                                  product?.title
                                                            }
                                                            to={`/products/${product?.slug}`}
                                                      >
                                                            {product?.sale_status ===
                                                                  1 && (
                                                                  <span
                                                                        className={`product-status sale`}
                                                                  >
                                                                        Sale
                                                                  </span>
                                                            )}
                                                            {product.stock_status && (
                                                                  <span className="stock-status">
                                                                        In Stock
                                                                  </span>
                                                            )}
                                                            <figure className="article-img">
                                                                  <img
                                                                        src={
                                                                              product?.image
                                                                        }
                                                                        alt={
                                                                              product?.title
                                                                        }
                                                                  />
                                                            </figure>
                                                            <h2 className="titles product-title">
                                                                  {
                                                                        product?.title
                                                                  }
                                                            </h2>
                                                            {/* <div className="price">
                                                                  {product.sale_price !==
                                                                  0 ? (
                                                                        <>
                                                                              <span className="current-price">
                                                                                    NRs.{" "}
                                                                                    {
                                                                                          product?.sale_price
                                                                                    }
                                                                              </span>
                                                                              <span className="old-price">
                                                                                    NRs.{" "}
                                                                                    {
                                                                                          product.price
                                                                                    }
                                                                              </span>
                                                                        </>
                                                                  ) : (
                                                                        <>
                                                                              <span className="current-price">
                                                                                    NRs.{" "}
                                                                                    {
                                                                                          product?.price
                                                                                    }
                                                                              </span>
                                                                        </>
                                                                  )}
                                                            </div> */}
                                                      </Link>
                                                      <Link
                                                            to={`/products/${product?.slug}`}
                                                      >
                                                            <Button
                                                                  title="Show More Details"
                                                                  className="btn rounded-btn text-btn mt-0"
                                                            >
                                                                  Explore Now
                                                            </Button>
                                                      </Link>
                                                </Card>
                                          </Col>
                                    ))
                        ) : (
                              <></>
                        )}
                        {limit < products.length && (
                              <div
                                    style={{
                                          textAlign: "center",
                                          padding: "10px",
                                    }}
                              >
                                    <Spinner />
                              </div>
                        )}
                  </Row>
            </>
      );
};

export default ProductList;
