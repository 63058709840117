import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
      <Router>
            <App />
      </Router>
);
