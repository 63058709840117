import React, { useEffect, useState } from "react";
import {
      ScrollUpHeaderContainer,
      ScrollUpHeaderLogo,
      ScrollUpHeaderNav,
} from "./styles";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import DropDown from "../DropDown";
import MobileNav from "../MobileNav";
import { menuLists } from "../../../../Data/categories";

const ScrollUpHeader = ({ loading, categories }) => {
      const [isScrolled, setIsScrolled] = useState(false);

      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.scrollY;
                  if (scrollTop > 500) {
                        // Change 100 to your desired scroll position
                        setIsScrolled(true);
                  } else {
                        setIsScrolled(false);
                  }
            };

            window.addEventListener("scroll", handleScroll);
            return () => {
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);
      return (
            <>
                  <ScrollUpHeaderContainer
                        className={`scroll-header ${
                              isScrolled ? "active" : ""
                        }`}
                        show={isScrolled}
                  >
                        <Container
                              fluid
                              className="d-flex align-items-center justify-content-between"
                        >
                              <ScrollUpHeaderLogo>
                                    <div className="header-logo d-flex align-items-center">
                                          <Link to={"/"}>
                                                <img
                                                      src="/banners/plumber.png"
                                                      alt="Plumber Pipes Pvt. Ltd."
                                                      className="img-fluid"
                                                />
                                          </Link>
                                    </div>
                              </ScrollUpHeaderLogo>
                              <ScrollUpHeaderNav>
                                    <div className="header-lists d-flex  ">
                                          <ul className="mb-0 ps-0">
                                                <li className="">
                                                      <Link to="/">Home</Link>
                                                </li>
                                                <li>
                                                      <Link to="/about-us">
                                                            About
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/products">
                                                            Products
                                                      </Link>
                                                      <DropDown
                                                            show={isScrolled}
                                                            loading={loading}
                                                            categories={
                                                                  categories
                                                            }
                                                      />
                                                </li>
                                                <li>
                                                      <Link to="/dealer-location">
                                                            Dealers
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/catalogue">
                                                            Catalogue
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/blogs">
                                                            Blogs
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/contact-us">
                                                            Contact
                                                      </Link>
                                                </li>
                                          </ul>
                                          <div className="header__contact d-flex gap-3 align-items-center pe-3 ms-4">
                                                <div className="header__contact--icon">
                                                      <img
                                                            src="/phone-call.png"
                                                            alt=""
                                                            className="img-fluid"
                                                            width={42}
                                                            height={42}
                                                      />
                                                </div>
                                                <div className="header__contant--info">
                                                      <div className="mb-0">
                                                            <a href="tel:97701536125">
                                                                  Need help?
                                                                  Talk to an
                                                                  expert
                                                            </a>
                                                      </div>
                                                      <p>
                                                            + (977) 01 - 5361925
                                                      </p>
                                                </div>
                                          </div>
                                          {/* <SocialMedia /> */}
                                    </div>
                              </ScrollUpHeaderNav>
                              <MobileNav category={menuLists} />
                        </Container>
                  </ScrollUpHeaderContainer>
            </>
      );
};

export default ScrollUpHeader;
