import React from "react";
import CustomHeadline from "../../../components/CustomHeadline";
import FeaturedProductSwiper from "./FeaturedProductSwiper";

const FeaturedProducts = ({ products }) => {
      return (
            <>
                  <CustomHeadline
                        subtitle={"Related Products"}
                        title={"Plumber "}
                        showButton={true}
                        variant={true}
                        right={true}
                        moreLink="/products"
                  />
                  <FeaturedProductSwiper products={products} />
            </>
      );
};

export default FeaturedProducts;
