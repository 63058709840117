import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import CustomHeadline from "../../components/CustomHeadline";
import "./style.css";
import StoreContainer from "./ProductFilter";
import ProductList from "./ProductCards";
import axios from "axios";
import "react-progress-2/main.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const ProductPage = () => {
      const { categoryId } = useParams();
      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);
      const [products, setProducts] = useState([]);
      const [searchName, setSearchName] = useState("");
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/categories/${categoryId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setCategory(response.data.category);
                        setProducts(response.data.products);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/filters/${categoryId}/categories`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      const [min, setMin] = useState("");
      const [max, setMax] = useState("");

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [selectedCategories, setSelectedCategories] = useState([]);

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === "category") {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === "subCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked,
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === "childCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked,
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory,
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const changePrice = async () => {
            const filters = [];

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: "category",
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: "subCategory",
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: "childCategory",
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked,
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(",")}`);
            }
            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/categories/${categoryId}${
                  filters.length > 0 ? "?" + filters.join("&") : "?"
            }${max > 0 ? `&search=${searchName}` : ``}`;

            await axios
                  .get(apiUrl, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            changePrice();
      }, [categories, searchName]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {category?.seo_title || category?.title}
                              </title>
                              {category?.seo_description && (
                                    <meta
                                          name="description"
                                          content={category?.seo_description}
                                    />
                              )}
                              {category?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}

                  <CustomBreadcrumb
                        title={category?.title}
                        subtitle={"Products"}
                  />

                  <Container className="sectionMargin marginBottom">
                        <CustomHeadline
                              title="Featured Products"
                              textAlign={"center"}
                        />
                        <Row className="g-6 mt-5">
                              <Col lg={3}>
                                    <StoreContainer
                                          loadingData={loading}
                                          categories={categories}
                                          handleChangeCategory={
                                                handleChangeCategory
                                          }
                                          setSearchName={setSearchName}
                                    />
                              </Col>
                              <Col lg={9}>
                                    <ProductList
                                          products={products}
                                          loading={loading}
                                          category={category}
                                          selectedCategories={
                                                selectedCategories
                                          }
                                    />
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default ProductPage;
