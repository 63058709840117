import styled from "styled-components";

export const CommonCategoryBox = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#f2f2f2")};
  width: 100%;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#ddd")};
  border-radius: 4px;
  position:sticky;
  top:82px;


  a {
    padding: 10px 15px;
    cursor: pointer;
    color: var(--gray-color);
    width: 100%;
    transition : backgrounColor 1s ease-in-out;  

     &:hover{
      background-color: var(--primary-color);
      color:${(props)=>props.afterHover?props.afterHover:'#fff'}
  }
  &.active{
      background-color: var(--primary-color);
      color:${(props)=>props.afterHover?props.afterHover:'#fff'}
  }
  }
  
  div:first-child {
    border-top: 0;
  }
  div {
    border-top: 1px solid #ddd;
  }
`;
