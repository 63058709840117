import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const DropDownContainer = styled.div.attrs(() => ({
      className: "dropdown",
}))`
      position: absolute;
      width: 753px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.01);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      border-radius: 0px 0px 15px 15px;
      left: 0px;
      ${"" /* top: 0px; */}
      a {
            color: ${(props) => props.theme.black} !important;
      }
`;
export const CategoryContainer = styled.div`
      height: 325px;
      overflow: hidden;
`;

export const CategoryList = styled.ul.attrs(() => ({
      className: "list-inline",
}))`
      border-right: 1px solid ${(props) => props.theme.lightBlack};
`;

export const CategoryListItem = styled.li`
      width: 100%;

      &:hover,
      &.active {
            background-color: ${(props) => props.theme.primary} !important;

            a {
                  color: ${(props) => props.theme.white} !important;
            }
      }

      &:first-child {
            border-radius: 0px 0px 0px 0px !important;
      }
      &:last-child {
            border-bottom-left-radius: 15px;
      }
`;

export const SubCategoryContent = styled.div`
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      padding: 20px;
      padding-left: 0;
`;

export const SubCategoryContainer = styled(Row)`
      ${"" /* display: none; */}
`;

export const CategoryImage = styled.div`
      padding: 20px;
      padding-bottom: 5px;

      img {
            height: 120px;
      }
`;

export const SubCategoryItem = styled(Col)`
      transition: box-shadow 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      a {
            padding: 0px !important;
            overflow: hidden;
            text-align: center;
            border-radius: 5px;

            ${CategoryImage} {
                  border: 1px solid ${(props) => props.theme.white};
                  border-radius: 5px;
                  padding: 0;
                  overflow: hidden;

                  img {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                        transition: all 0.3s ease-in-out;
                  }
            }
      }

      &:hover {
            a {
                  ${CategoryImage} {
                        img {
                              transform: scale(1.1);
                        }
                  }
            }
      }
`;

export const CategoryTitle = styled.div``;
