import React from "react";
import { Tabs, Tab, TabContent } from "react-bootstrap";

const ProductDetailsTab = ({ product }) => {
      return (
            <>
                  <Tabs
                        defaultActiveKey="description"
                        id="product-details-tabs"
                        className="mb-3"
                  >
                        <Tab
                              eventKey="description"
                              title="Description"
                              className="description-productdetails"
                        >
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: product?.description,
                                    }}
                              ></div>
                        </Tab>

                        <Tab
                              eventKey="reviews"
                              title="Additional Information"
                              className="description-productdetails"
                        >
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: product?.additional,
                                    }}
                              ></div>
                        </Tab>
                  </Tabs>
            </>
      );
};

export default ProductDetailsTab;
