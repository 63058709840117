import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import "./style.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import ProductSwiper from "./ProductSwiper";
import ProductDetailContent from "./ProductContent";
import ProductDetailsTab from "./ProductTabs";
import FeaturedProducts from "./FeaturedProducts";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { TabContent } from "./ProductSwiper/data";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
      const { productId } = useParams();
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [product, setProduct] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProduct(response.data.product);
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [productId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {product?.seo_title || product?.title}
                              </title>
                              {product?.seo_description && (
                                    <meta
                                          name="description"
                                          content={product?.seo_description}
                                    />
                              )}
                              {product?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={product?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb
                        title={product?.title}
                        subtitle={
                              <Link
                                    to={`/products/${product?.category_slug}/category`}
                              >
                                    {product?.category}
                              </Link>
                        }
                  >
                        {product?.parent_category_name && (
                              <Link
                                    to={`/products/${product?.parent_category}/category`}
                              >
                                    {product?.parent_category_name}
                              </Link>
                        )}
                  </CustomBreadcrumb>
                  <Container className="sectionMargin marginBottom">
                        <Row className="g-6 mt-3">
                              <Col lg={5}>
                                    {!loading ? (
                                          <ProductSwiper
                                                product={product}
                                                images={product?.images}
                                          />
                                    ) : (
                                          <Spinner />
                                    )}
                              </Col>
                              <Col lg={7}>
                                    <ProductDetailContent product={product} />
                              </Col>
                        </Row>
                        <TabContent className="g-6 mt-3 py-5">
                              <ProductDetailsTab product={product} />
                        </TabContent>
                        <FeaturedProducts products={products} />
                  </Container>
            </>
      );
};

export default ProductDetails;
