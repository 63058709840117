import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const HomeFeaturesContainer = styled.div`
      padding: 5rem 0px;
      background-image: url(/awards/pattern_background.png);
      background-size: cover;
      background-position: top center;
      position: relative;
      padding-bottom: 0px;

      @media screen and (max-width: 568px) {
            .col-lg-8 {
                  margin-bottom: 2rem;
            }
      }
`;
export const HomeFeatureImage = styled.div`
      position: absolute;
      left: 90px;
      top: -39px;
      img {
            width: 34px;
            height: 489px;
      }
`;

export const HomeFeatureWrapper = styled.div`
      background: #f1f1f1;
      border-radius: 15px;
      padding: 40px 0px 0px;
`;
export const HomeFeatureAmbassadder = styled.div`
      img {
            width: 301px;
      }

      @media screen and (max-width: 568px) {
            justify-content: center;
      }
`;

export const HomeFeatureContent = styled.div`
      @media screen and (max-width: 568px) {
            padding: 20px;

            .mb-5 {
                  margin-bottom: 4rem !important;
            }
      }
`;
export const HomeFeatureTitle = styled.h3`
      font-family: "Roboto", sans-serif;
      span {
            color: var(--primary-color);
            font-family: "Roboto", sans-serif;
      }
`;
export const HomeFeatureDesc = styled.p`
      font-size: 17px;
      font-weight: 400;
      .hot {
            color: #e9252b;
      }
      .cold {
            color: var(--primary-color);
      }
`;

export const FeatureList = styled(Row)``;
export const FeatureListItem = styled(Col)``;
export const FeatureListTitle = styled.h4`
      font-size: 14px;
`;
export const FeatureListIcon = styled.div`
      img {
            width: 40px;
            height: auto;
      }
`;

export const FeatureListImage = styled.div`
      grid-column-gap: 1px;
      grid-row-gap: 1px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-radius: 15px;
      ${"" /* padding: 40px 20px; */}
      justify-items: center;
      align-items: center;
      img {
            width: 150px;
      }
`;
export const HomeFeatureBox = styled.div`
      background-color: #f1f1f1;
      padding: 40px 20px;
      border-radius: 15px;
`;
