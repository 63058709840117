import { Container } from "react-bootstrap";
import styled from "styled-components";

export const ProductInstallationContainer = styled(Container)``;
export const InstallationImage = styled.div`
	height: 500px;
	width: 600px;
	background: #ffffff;
	padding: 26px;
	border-radius: 22px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
export const InstallationContent = styled.div`
	p {
		color: #212121;
		text-align: justify;
		padding-right: 42px;
	}
`;
