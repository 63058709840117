import React from "react";
import {
      FeatureList,
      FeatureListIcon,
      FeatureListImage,
      FeatureListItem,
      FeatureListTitle,
      HomeFeatureAmbassadder,
      HomeFeatureBox,
      HomeFeatureContent,
      HomeFeatureDesc,
      HomeFeatureTitle,
      HomeFeatureWrapper,
      HomeFeaturesContainer,
} from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import WhiteButton from "../../../../CommonButton/WhiteButton";
import CommonButton from "../../../../CommonButton";

const features = [
      {
            id: 1,
            title: "Strong and Light Weight",
            image: "/banners/feature1.png",
      },
      {
            id: 2,
            title: "Durable",
            image: "banners/feature2.png",
      },
      {
            id: 3,
            title: "Fire Resistant",
            image: "banners/feature3.png",
      },
      {
            id: 4,
            title: "Easy to Install",
            image: "banners/feature4.png",
      },
      {
            id: 5,
            title: "Safe Material for Drinking Water",
            image: "banners/feature5.png",
      },
      {
            id: 6,
            title: "UV Stablilized",
            image: "banners/feature6.png",
      },
];
const HomeFeatures = () => {
      return (
            <>
                  <HomeFeaturesContainer>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <HomeFeatureWrapper className="d-flex  row g-0 h-100">
                                                <HomeFeatureAmbassadder className="col-sm-5 d-flex align-items-end">
                                                      <img
                                                            src="banners/brand.png"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </HomeFeatureAmbassadder>
                                                <HomeFeatureContent className="col-sm-7 mb-5">
                                                      <HomeFeatureTitle>
                                                            <span>
                                                                  Plumber CPVC{" "}
                                                            </span>
                                                            Piping
                                                      </HomeFeatureTitle>
                                                      <HomeFeatureDesc className="mb-4">
                                                            <span className="hot">
                                                                  Hot{" "}
                                                            </span>
                                                            when you want it.
                                                            <span className="cold">
                                                                  Cold
                                                            </span>{" "}
                                                            when you need it.
                                                      </HomeFeatureDesc>
                                                      <FeatureList className="g-3 mb-4">
                                                            {features.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <FeatureListItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                              className="d-flex align-items-center gap-2"
                                                                              sm={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <FeatureListIcon>
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                          className="img-fluid"
                                                                                    />
                                                                              </FeatureListIcon>
                                                                              <FeatureListTitle>
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                              </FeatureListTitle>
                                                                        </FeatureListItem>
                                                                  )
                                                            )}
                                                      </FeatureList>
                                                      <CommonButton
                                                            title={"Know More"}
                                                            slug={"/about-us"}
                                                      />
                                                </HomeFeatureContent>
                                          </HomeFeatureWrapper>
                                    </Col>
                                    <Col lg={4}>
                                          <HomeFeatureBox className="h-100">
                                                <HomeFeatureTitle
                                                      className="text-center"
                                                      style={{
                                                            fontSize: "19px",
                                                      }}
                                                >
                                                      <span>Unmatched </span>
                                                      Product Offerings
                                                </HomeFeatureTitle>
                                                <FeatureListImage className="h-100">
                                                      <img
                                                            src="banners/side1.png"
                                                            alt=""
                                                            className="first"
                                                      />
                                                      <img
                                                            src="banners/side2.png"
                                                            alt=""
                                                      />
                                                      <img
                                                            src="banners/side3.png"
                                                            alt=""
                                                      />
                                                      <img
                                                            src="banners/side4.png"
                                                            alt=""
                                                      />
                                                </FeatureListImage>
                                          </HomeFeatureBox>
                                    </Col>
                              </Row>
                        </Container>
                  </HomeFeaturesContainer>
            </>
      );
};

export default HomeFeatures;
