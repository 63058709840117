import React from "react";

const SendIcon = () => {
  return (
    <svg
      id="fi_9333991"
      enableBackground="new 0 0 548.244 548.244"
      viewBox="0 0 548.244 548.244"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="Icon_00000145049271370995700300000003487412756501951400_">
          <path d="m392.19 156.054-180.922 125.613-189.236-63.087c-13.209-4.412-22.108-16.805-22.032-30.728.077-13.923 9.078-26.24 22.338-30.498l483.812-155.805c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666l-155.805 483.811c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"></path>
        </g>
      </g>
    </svg>
  );
};

export default SendIcon;
