import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const CategoryFilterContainer = styled(Accordion)``;

export const CategoryFilterHeader = styled(Accordion.Header)`
      button {
            padding: 0;
            background-color: transparent !important;
            box-shadow: none !important;
            font-weight: 400;
            font-size: 1rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
`;

export const CategoryFilterBody = styled(Accordion.Body)`
      padding: 0;

      ul {
            padding-left: 0;
            list-style: none;
            margin: 0;

            li {
                  /* border-bottom: 1px solid #dee2e6;
                  padding-bottom: 5px;
                  padding-top: 5px; */

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }

            .accordion {
                  border-bottom: 1px solid #dee2e6 !important;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }
      }
`;

export const StoreWidgetBody = styled.div`
      padding-left: 10px;
      padding-right: 10px;
      max-height: 300px;
      overflow: hidden auto;
      overflow-x: hidden !important;

      .MuiFormControlLabel-root {
            margin: 0;

            .MuiCheckbox-root {
                  margin-right: -9px;
            }
      }

      .accordion {
            --bs-accordion-bg: transparent;

            .accordion-item {
                  border: 0;

                  .accordion-button {
                        padding: 10px 0;
                        font-size: 16px;

                        &:focus {
                              box-shadow: none;
                        }

                        &:not(.collapsed) {
                              background-color: transparent;
                              box-shadow: none;
                        }

                        .collapsed {
                              padding: 10px 0;
                              font-size: 18px;
                        }

                        &:hover {
                              color: var(--primary-hover);
                        }
                  }

                  .accordion-body {
                        padding: 0;
                  }
            }
      }

      &::-webkit-scrollbar {
            width: 6px;
      }

      &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            transition: background 0.3s ease-in-out;
      }

      &::-webkit-scrollbar-thumb:hover {
            background: #555;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
      }

      input[type="number"] {
            -moz-appearance: textfield;
      }

      form {
            button {
                  border: var(--bs-border-width) solid var(--bs-border-color);
                  background-color: var(--primary);
                  color: white;
                  border-radius: 5px;
            }
      }
`;
