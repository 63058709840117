import React, { useContext } from "react";
import CareerJoblists from "./components/CareerJobLists";
import { Col, Container, Row } from "react-bootstrap";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import CustomHeadline from "../../components/CustomHeadline";
import CareerContext from "../../Store/career-lists";
import CareerDescription from "./components/CareerDescription";
import { MetaContext } from "../../Store";

const CareerPage = () => {
      const careerCtx = useContext(CareerContext);
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("careers");

      return (
            <>
                  <CustomBreadcrumb title={"Careers"} subtitle={"Careers"} />
                  <div className="sectionMargin marginBottom">
                        <CustomHeadline
                              title={"Join Us"}
                              subtitle={
                                    "Join us and grow with a skilled, friendly team!"
                              }
                              textAlign={"center"}
                        />
                        <Container>
                              <Row className="sectionMargin">
                                    <Col lg="3">
                                          <CareerJoblists
                                                jobs={careerCtx.careers}
                                          />
                                    </Col>
                                    <Col lg="9">
                                          <CareerDescription
                                                jobs={careerCtx.careers[0]}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </div>
                  {/* <CustomFooter/> */}
            </>
      );
};

export default CareerPage;
