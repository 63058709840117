import styled from "styled-components";

export const LearnMoreBox = styled.div`
  h5 {
    font-size: 21px;
    font-weight: 400;
  }
  background: linear-gradient(to left, rgb(14, 149, 179) 1%, rgb(0, 20, 94));
  padding: 40px 30px;
`;
