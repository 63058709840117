import { Modal, Row } from "react-bootstrap";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  .modal-header {
    border: 0;
    padding: 1.5rem 3rem;
  }
  .modal-title {
    font-size: 21px;
  }
  .modal-footer {
    border: 0;
  }
  .modal-content {
  }
  .modal-body {
    padding: 0 3rem;
  }
`;
export const ModalRow = styled(Row)`
  border: 1px solid #ddd;
  padding: 15px 0;
`;
