import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav, Card } from "react-bootstrap";
import "./style.css";
import axios from "axios";

const CompanyIntroAbout = () => {
      const [loading, setLoading] = useState(false);
      const [segments, setSegments] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/business_segments`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSegments(response.data.segments);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <div className="products__solutions">
                  <Container>
                        <Row>
                              <Col
                                    xs={12}
                                    md={8}
                                    lg={8}
                                    className="mx-auto text-center"
                              >
                                    <div className="products__solutions-title">
                                          <h2>Plumber Pipes Nepal Pvt. Ltd.</h2>
                                          <h3>Our Business Segments</h3>
                                          <p>
                                                As a pioneer in hot and cold
                                                plumbing systems, our portfolio
                                                features the widest range of
                                                PVC, UPVC, and CPVC pipes and
                                                fittings used across a variety
                                                of applications in different
                                                market segments.
                                          </p>
                                    </div>
                              </Col>
                        </Row>

                        <Row>
                              <Col xs={12}>
                                    <div className="service__tab-wrapper">
                                          <Tab.Container
                                                id="products-tabs"
                                                defaultActiveKey="Building"
                                          >
                                                <Nav
                                                      variant="pills"
                                                      className="flex-column"
                                                >
                                                      {segments?.map(
                                                            (item, index) => (
                                                                  <Nav.Item
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Nav.Link
                                                                              eventKey={
                                                                                    item?.title
                                                                              }
                                                                        >
                                                                              <h3>
                                                                                    {
                                                                                          item?.title
                                                                                    }
                                                                              </h3>
                                                                        </Nav.Link>
                                                                  </Nav.Item>
                                                            )
                                                      )}
                                                </Nav>

                                                <Tab.Content>
                                                      {segments?.map(
                                                            (item, index) => (
                                                                  <Tab.Pane
                                                                        eventKey={
                                                                              item?.title
                                                                        }
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Card className="tab-pane-card">
                                                                              <Card.Body>
                                                                                    <div className="service-content">
                                                                                          <div className="contents-wrap">
                                                                                                <img
                                                                                                      src={
                                                                                                            item?.image
                                                                                                      }
                                                                                                      className="tab-img"
                                                                                                      alt={
                                                                                                            item?.title
                                                                                                      }
                                                                                                />
                                                                                                <div className="contents-bottom">
                                                                                                      <p>
                                                                                                            {
                                                                                                                  item?.description
                                                                                                            }
                                                                                                      </p>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </Card.Body>
                                                                        </Card>
                                                                  </Tab.Pane>
                                                            )
                                                      )}
                                                </Tab.Content>
                                          </Tab.Container>
                                    </div>
                              </Col>
                        </Row>
                  </Container>
            </div>
      );
};

export default CompanyIntroAbout;
