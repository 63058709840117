import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import CategoryManage from "./CategoryManage";
import { StoreWidgetBody } from "./style";

const StoreContainer = ({
      loadingData,
      categories,
      handleChangeCategory,
      setSearchName,
}) => {
      const [text, setText] = useState("");
      const [loading, setLoading] = useState(false);

      const handleSearch = async () => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
                  setSearchName(text);
            }, 3000);
      };

      return (
            <>
                  <div className="sticky store__container">
                        <div className="store__content">
                              <div className="search__bar">
                                    <Form
                                          onSubmit={handleSearch}
                                          className="d-flex align-items-center justify-content-between"
                                    >
                                          <Form.Control
                                                type="text"
                                                placeholder="Search product"
                                                className="rounded-0"
                                                onChange={(event) =>
                                                      setText(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                          <Button
                                                type="submit"
                                                className="border-0 rounded-circle d-flex align-items-center justify-content-center px-2 py-2  btn-primary"
                                          >
                                                {!loading ? (
                                                      <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-search"
                                                            viewBox="0 0 16 16"
                                                      >
                                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                      </svg>
                                                ) : (
                                                      <Spinner />
                                                )}
                                          </Button>
                                    </Form>
                              </div>

                              <div className="store__widget mt-4 mb-5">
                                    <h2 className="store__widget-title">
                                          Product Category
                                    </h2>
                                    <hr />
                                    <StoreWidgetBody className="store__widget-body">
                                          <CategoryManage
                                                categories={categories}
                                                loading={loadingData}
                                                handleChangeCategory={
                                                      handleChangeCategory
                                                }
                                          />
                                    </StoreWidgetBody>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default StoreContainer;
