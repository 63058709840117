import React from "react";
import { CompanyImage, WhyUsBox, WhyUsContent } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import CustomHeadline from "../../../../components/CustomHeadline";
import {
      CountWrapper,
      CounterContainer,
      CounterInfo,
} from "../../../Home/components/HomeAbout/styles";
import CountUp from "react-countup";

const WhyUS = () => {
      const progress = [
            {
                  id: 1,
                  title: "Countries Served",
                  count: "20",
                  icon: "true",
            },
            {
                  id: 2,
                  title: "Employees",
                  count: "190",
                  icon: "true",
            },
            {
                  id: 3,
                  title: "Manufacturing Units",
                  count: "20",
            },
      ];
      return (
            <>
                  <WhyUsBox>
                        <Container>
                              <Row>
                                    <Col lg="6">
                                          <CompanyImage>
                                                {/* <img
                                                      src="/about/company.jpg"
                                                      alt=""
                                                      className="img-fluid"
                                                /> */}
                                                <video
                                                      autoPlay={true}
                                                      loop={true}
                                                      muted={true}
                                                      src="/plumber.mp4"
                                                ></video>
                                          </CompanyImage>
                                    </Col>
                                    <Col lg="6">
                                          <WhyUsContent>
                                                <CustomHeadline
                                                      title="About Plumber Pipes Nepal "
                                                      right={true}
                                                />

                                                <p className="mt-3">
                                                      Plumber Pipes stands for
                                                      innovation and setting new
                                                      trends in the piping
                                                      industry. Bringing newer
                                                      piping technologies and
                                                      continuous innovation in
                                                      existing as well as new
                                                      products has been the
                                                      focal point at Plumber
                                                      Pipes. This special
                                                      emphasis helps the brand
                                                      set the bar higher and
                                                      lead amongst others by
                                                      example.
                                                      <p className="mt-2">
                                                            {" "}
                                                            Plumber Pipes is
                                                            also known for its
                                                            compromise-free
                                                            quality and
                                                            exceeding consumer’s
                                                            expectations. Right
                                                            from introducing new
                                                            piping technologies
                                                            to innovative brand
                                                            communications,
                                                            Plumber Pipes’s
                                                            brand mission has
                                                            been to maintain and
                                                            grow a commanding
                                                            presence in the
                                                            minds of customers
                                                            and to deliver
                                                            promised values,
                                                            consistently.
                                                      </p>
                                                </p>

                                                <h1 className="mt-3">
                                                      Born In KTM, Nepal
                                                      <br /> Making Waves Across
                                                      The World
                                                </h1>
                                          </WhyUsContent>
                                    </Col>
                                    <Col lg="12" className="my-2">
                                          <CounterContainer className="my-4">
                                                {progress?.map(
                                                      (item, index) => (
                                                            <Col
                                                                  key={index}
                                                                  className="d-flex flex-column align-items-center"
                                                            >
                                                                  <CountWrapper
                                                                        className="d-flex gap-2"
                                                                        changeColor
                                                                  >
                                                                        <CountUp
                                                                              end={
                                                                                    item.count
                                                                              }
                                                                              enableScrollSpy
                                                                        />
                                                                        {item.icon && (
                                                                              <span>
                                                                                    +
                                                                              </span>
                                                                        )}
                                                                  </CountWrapper>

                                                                  <CounterInfo
                                                                        changeColor
                                                                  >
                                                                        <span>
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </span>
                                                                  </CounterInfo>
                                                            </Col>
                                                      )
                                                )}
                                          </CounterContainer>
                                    </Col>
                              </Row>
                        </Container>
                  </WhyUsBox>
            </>
      );
};

export default WhyUS;
