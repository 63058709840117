import { Row } from "react-bootstrap";
import styled from "styled-components";

export const CounterContainer = styled(Row)``;

export const CounterInfo = styled.div`
      span {
            color: ${(props) =>
                  props.changeColor ? "var(--gray-color)" : "#555"};
      }
`;

export const CountWrapper = styled.div`
      span {
            font-size: 40px;
            font-weight: 500;
            color: ${(props) =>
                  props.changeColor ? "var(--primary-color)" : "#212121"};
      }
`;

export const SectionHomeAbout = styled.div`
      @media screen and (max-width: 568px) {
            padding-right: 0;

            .inner-home__about {
                  .g-5 {
                        --bs-gutter-x: 0;
                  }
            }
      }
`;

export const SectionHomeAboutImage = styled.div`
      padding: 40px 40px 40px 30px;
      padding-left: 0;
      position: relative;
      overflow: hidden;
      height: 145px;
      width: 100%;

      video {
            position: relative;
            z-index: 10;
            height: 100%;
            width: 100%;
            transform: scale(1.4);
            border-radius: 15px;
      }

      /* &:before {
            position: absolute;
            background: var(--primary-color);
            content: "";
            height: 100%;
            width: 70%;
            border-radius: 15px;
            left: -100px;
            top: -0px;
      } */

      &:after {
            position: absolute;
            width: 400px;
            height: 400px;
            background: #8c9cc3;
            top: 0px;
            left: 60px;
            border-radius: 50%;
            z-index: 1;
            opacity: 0.4;
      }

      @media screen and (max-width: 568px) {
            padding: 0;
      }
`;
