import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import CustomHeadline from "../../../../components/CustomHeadline";
import {
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      FormSelect,
      Row,
      Spinner,
} from "react-bootstrap";
import "./style.css";
import $ from "jquery";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const CareerForm = () => {
      const { careerId } = useParams();

      const [loading, setLoading] = useState(false);
      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [email, setEmail] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [gender, setGender] = useState("");
      const [currentAddress, setCurrentAddress] = useState("");
      const [permanentAddress, setPermanentAddress] = useState("");
      const [dateOfBirth, setDateOfBirth] = useState("");
      const [aboutYourself, setAboutYourself] = useState("");
      const [resume, setResume] = useState("");
      const [education, setEducation] = useState("");
      const [experience, setExperience] = useState("");

      const [career, setCareer] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCareer(response.data.job);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [careerId]);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  gender: gender,
                  current_address: currentAddress,
                  permanent_address: permanentAddress,
                  date_of_birth: dateOfBirth,
                  about_yourself: aboutYourself,
                  resume: resume,
                  qualification: education,
                  experience: experience,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setFirstName("");
                              setLastName("");
                              setGender("");
                              setDateOfBirth("");
                              setCurrentAddress("");
                              setPermanentAddress("");
                              setEmail("");
                              setPhoneNumber("");
                              setResume("");
                              setEducation("");
                              setExperience("");
                              setAboutYourself("");
                              $("form").trigger("reset");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.current_address) {
                                    toast.error(
                                          response.data.message.current_address
                                    );
                              }
                              if (response.data.message.permanent_Address) {
                                    toast.error(
                                          response.data.message
                                                .permanent_Address
                                    );
                              }
                              if (response.data.message.gender) {
                                    toast.error(response.data.message.gender);
                              }
                              if (response.data.message.education) {
                                    toast.error(
                                          response.data.message.education
                                    );
                              }
                              if (response.data.message.date_of_birth) {
                                    toast.error(
                                          response.data.message.date_of_birth
                                    );
                              }
                              if (response.data.message.resume) {
                                    toast.error(response.data.message.resume);
                              }
                              if (response.data.message.about_yourself) {
                                    toast.error(
                                          response.data.message.about_yourself
                                    );
                              }
                              if (response.data.message.experience) {
                                    toast.error(
                                          response.data.message.experience
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {career?.seo_title ||
                                          `Apply for ${career?.title}`}
                              </title>
                              {career?.seo_description && (
                                    <meta
                                          name="description"
                                          content={career?.seo_description}
                                    />
                              )}
                              {career?.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={career?.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <CustomBreadcrumb
                        title={`Apply for ${career?.title}`}
                        subtitle={"CAREER FORM"}
                  />

                  <div className=" sectionMargin marginBottom">
                        <Container>
                              <Row className="justify-content-between">
                                    <Col lg={12}>
                                          <CustomHeadline
                                                title={"Apply Now"}
                                                subtitle={
                                                      "Fill out the form below to apply for this position!"
                                                }
                                                textAlign={"center"}
                                          />
                                    </Col>
                                    <Col lg="8" className="mb-4 mx-auto">
                                          <Form onSubmit={handleForm}>
                                                <div className="career-form mt-4">
                                                      <Row>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        First
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        type="text"
                                                                        value={
                                                                              firstName
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setFirstName(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        placeholder="First Name"
                                                                  />
                                                            </Col>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Last
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              lastName
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setLastName(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="text"
                                                                        placeholder="Last Name"
                                                                  />
                                                            </Col>
                                                      </Row>
                                                      <Row>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Email
                                                                        Address
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              email
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setEmail(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="email"
                                                                        placeholder="Email Address"
                                                                  />
                                                            </Col>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Phone
                                                                        No.
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              phoneNumber
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setPhoneNumber(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="text"
                                                                        placeholder="Phone No."
                                                                  />
                                                            </Col>
                                                      </Row>
                                                      <Row>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Date of
                                                                        Birth
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              dateOfBirth
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setDateOfBirth(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="date"
                                                                        placeholder="Date of Birth"
                                                                  />
                                                            </Col>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Gender
                                                                  </FormLabel>
                                                                  <FormSelect
                                                                        value={
                                                                              gender
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setGender(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  >
                                                                        <option value="">
                                                                              Select
                                                                              One
                                                                        </option>
                                                                        <option value="Male">
                                                                              Male
                                                                        </option>
                                                                        <option value="Female">
                                                                              Female
                                                                        </option>
                                                                  </FormSelect>
                                                            </Col>
                                                      </Row>
                                                      <Row>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Current
                                                                        Address
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              currentAddress
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setCurrentAddress(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="text"
                                                                        placeholder="Current Address"
                                                                  />
                                                            </Col>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Permanent
                                                                        Address
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        required
                                                                        value={
                                                                              permanentAddress
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setPermanentAddress(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        type="text"
                                                                        placeholder="Permanent Address"
                                                                  />
                                                            </Col>
                                                      </Row>
                                                      <Row>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Education
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        value={
                                                                              education
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setEducation(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        required
                                                                        type="text"
                                                                        placeholder="Education"
                                                                  />
                                                            </Col>
                                                            <Col
                                                                  lg="6"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Experience
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        value={
                                                                              experience
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setExperience(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        required
                                                                        type="text"
                                                                        placeholder="Experience"
                                                                  />
                                                            </Col>
                                                      </Row>
                                                      <Row>
                                                            <Col
                                                                  lg="12"
                                                                  className="mb-4"
                                                            >
                                                                  <FormLabel>
                                                                        Resume{" "}
                                                                        <span className="text-danger">
                                                                              *
                                                                        </span>{" "}
                                                                        <span className="text-danger">
                                                                              (docx,
                                                                              pdf,
                                                                              dox)
                                                                        </span>
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setResume(
                                                                                    event
                                                                                          .target
                                                                                          .files[0]
                                                                              )
                                                                        }
                                                                        required
                                                                        type="file"
                                                                  />
                                                            </Col>
                                                      </Row>
                                                      <Col
                                                            lg="12"
                                                            className="mb-4"
                                                      >
                                                            <FormLabel>
                                                                  About your
                                                                  self
                                                            </FormLabel>
                                                            <FormControl
                                                                  as={
                                                                        "textarea"
                                                                  }
                                                                  value={
                                                                        aboutYourself
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setAboutYourself(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  rows="7"
                                                                  cols="20"
                                                                  placeholder="Message"
                                                            />
                                                      </Col>
                                                      <div className="col-md-12">
                                                            <div className="contact-btn">
                                                                  <button
                                                                        type="submit"
                                                                        className="btn btn btn-primary"
                                                                  >
                                                                        {loading ? (
                                                                              <Spinner />
                                                                        ) : (
                                                                              "Apply Now"
                                                                        )}
                                                                  </button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Form>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </>
      );
};

export default CareerForm;
