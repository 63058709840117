import { Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const GalleryContainer = styled(Container)`
      .nav-link {
            color: #222;
      }

      .nav-item {
            &:first-child {
                  .nav-link {
                        background-color: #f1f1f1;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;

                        &.active {
                              background-color: var(--primary-color);
                              color: #fff;
                        }
                  }
            }

            &:last-child {
                  .nav-link {
                        background-color: #f1f1f1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;

                        &.active {
                              background-color: var(--primary-color);
                              color: #fff;
                        }
                  }
            }
      }
`;
export const GalleryRow = styled(Row)``;

export const ToggleButtonBox = styled.div`
      width: 100%;
      ul {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0rem;
      }
      li {
            list-style: none;
      }
      button {
            display: block;
            padding: 0.5rem 1rem;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
      }
`;
export const GalleryHover = styled.div`
      div {
            width: 100%;
      }

      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: #1c36779c;
      transition: all 0.3s ease;
      color: #fff;
      h5 {
            position: absolute;
            bottom: 15px;
            padding: 0 2rem;
            font-size: 18px;
            font-weight: 700;
            text-align: left;
      }
      span {
            display: block;
            font-size: 14px;
            font-weight: normal;
      }
`;

export const SingleGallery = styled.div`
      width: 100%;
      height: 250px;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
      a {
            width: 100%;
            display: inline-block;
            height: 100%;
            position: relative;
            &:hover ${GalleryHover} {
                  width: 100%;
                  height: 100%;
                  opacity: 1;
                  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
            }
      }
      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
      }
`;
