import React, { useContext } from "react";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import WhyUS from "./components/WhyUs";
import AboutMilestone from "./components/AboutMilestone";
import AboutFactoryNetwork from "./components/AboutFactoryNetwork";
import AboutAwardAndRecognition from "./components/AboutAward&Recognition";
import CompanyIntroAbout from "./components/CompanyIntro";
import { MetaContext } from "../../Store";

const AboutPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("about-us");

      return (
            <>
                  <CustomBreadcrumb title={"ABOUT US"} subtitle={"ABOUT"} />
                  <div className="sectionMargin">
                        <WhyUS />
                        <CompanyIntroAbout />
                        <AboutMilestone />
                        <AboutFactoryNetwork />
                        <AboutAwardAndRecognition />
                  </div>
            </>
      );
};

export default AboutPage;
