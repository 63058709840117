import styled from "styled-components";
import { CategoryTitle, DropDownContainer } from "../DropDown/styles";

export const ScrollUpHeaderContainer = styled.div`
      position: relative;
      position: fixed;
      top: -100px;
      width: 100%;
      background: ${(props) => props.theme.white};
      transition: top 0.5s linear;
      z-index: 1000;
      padding: 10px 0;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      &.active {
            top: 0;
      }

      &:after {
            content: "";
            background: ${(props) => props.theme.primary};
            clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 185%);
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 330px;
            z-index: -1;
      }

      ${DropDownContainer} {
            display: ${(props) => (props.show ? "" : "none")} !important;
            background-color: rgba(255, 255, 255);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.01);
            -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
            .list-inline {
                  border-right: 1px solid #dcdcdc;
            }
            li {
                  &:hover {
                        background-color: ${(props) => props.theme.primary};
                        a {
                              color: ${(props) => props.theme.white} !important;
                        }
                  }
            }
            li a {
                  color: #212121 !important;
                  padding: 13px 20px !important;
            }
            ${CategoryTitle} {
                  color: #212121 !important;
            }
      }

      @media screen and (max-width: 568px) {
            top: 0;
            padding: 0;

            &:after {
                  content: "";
                  background: ${(props) => props.theme.primary};
                  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 185%);
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  width: 230px;
                  z-index: -1;
            }
      }
`;
export const ScrollUpHeaderLogo = styled.div`
      padding-left: 40px;
      @media screen and (max-width: 1100px) {
            padding: 10px 40px;
      }

      @media screen and (max-width: 568px) {
            padding-left: 0;
      }
`;
export const ScrollUpHeaderNav = styled.div`
      .header-lists li a {
            color: #212121;
            font-weight: 400;
            padding: 13px 20px;
      }
      .header-lists {
            background-image: unset;
      }
      .header-lists li {
            &:hover {
                  a {
                        color: var(--primary-color);
                  }
            }
      }
`;
