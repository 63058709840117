import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";

export const ShareModal = styled(Modal)`
      .modal-dialog {
            z-index: 999999;
      }
`;

export const ProductDetailQuality = styled.div`
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 10px;
      margin: 25px 0;

      &.btn {
            border-radius: 0;

            &:hover {
                  background-color: var(--yellow-color);
            }
      }

      .form-control,
      .form-select {
            padding: 0px !important;
            font-size: 16px;
            font-weight: 300;
            border-style: solid;
            border-radius: 0;
            border-width: 1px 0px 1px 0px;
            border-color: var(--primary-color);
      }
`;

export const ProductForm = styled(Form)`
      .form-control {
            padding-left: 15px;
            padding-right: 15px;

            &:focus {
                  box-shadow: none;
            }
      }
`;
