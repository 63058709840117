import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const ContactFormList = () => {
      const [loading, setLoading] = useState(false);
      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [email, setEmail] = useState("");
      const [message, setMessage] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  message: message,
                  phone_number: phoneNumber,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setEmail("");
                              setMessage("");
                              setFirstName("");
                              setLastName("");
                              setPhoneNumber("");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col md="6" className="mb-4">
                                    <div className="input-field">
                                          <FormControl
                                                type="text"
                                                placeholder="First Name"
                                                required
                                                onChange={(event) =>
                                                      setFirstName(
                                                            event.target.value
                                                      )
                                                }
                                                value={firstName}
                                          />
                                    </div>
                              </Col>
                              <Col md="6">
                                    <div className="input-field">
                                          <FormControl
                                                type="text"
                                                placeholder="Last Name"
                                                required
                                                onChange={(event) =>
                                                      setLastName(
                                                            event.target.value
                                                      )
                                                }
                                                value={lastName}
                                          />
                                    </div>
                              </Col>

                              <Col md="6" className="mb-4">
                                    <div className="input-field">
                                          <FormControl
                                                type="email"
                                                placeholder="Email Address"
                                                required
                                                onChange={(event) =>
                                                      setEmail(
                                                            event.target.value
                                                      )
                                                }
                                                value={email}
                                          />
                                    </div>
                              </Col>
                              <Col md="6">
                                    <div className="input-field">
                                          <FormControl
                                                type="text"
                                                placeholder="Phone Number"
                                                required
                                                onChange={(event) =>
                                                      setPhoneNumber(
                                                            event.target.value
                                                      )
                                                }
                                                value={phoneNumber}
                                          />
                                    </div>
                              </Col>
                              <Col md="12" className="mb-4">
                                    <textarea
                                          className="form-control"
                                          rows={"6"}
                                          cols="20"
                                          onChange={(event) =>
                                                setMessage(event.target.value)
                                          }
                                          value={message}
                                          placeholder="Message"
                                    />
                              </Col>
                              <Col md="12">
                                    <div className="contact-btn">
                                          <Button className="btn" type="submit">
                                                {loading ? <Spinner /> : "Send"}
                                          </Button>
                                    </div>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ContactFormList;
