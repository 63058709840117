import React, { useEffect, useState } from "react";
import { AboutMilestoneBox, SingleMilestone } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import CommonSwiper from "../../../../components/CommonSwiper";
import { SwiperSlide } from "swiper/react";
import CustomHeadline from "../../../../components/CustomHeadline";
import axios from "axios";

const dynamicBreakpoints = {
      640: {
            slidesPerView: 3,
            spaceBetween: 0,
      },
      768: {
            slidesPerView: 4,
            spaceBetween: 0,
      },
      1024: {
            slidesPerView: 5,
            spaceBetween: 0,
      },
};

const AboutMilestone = () => {
      const [loading, setLoading] = useState(false);
      const [milestones, setMilestones] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/milestones`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setMilestones(response.data.milestones);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <AboutMilestoneBox>
                        <Container className="py-5">
                              <CustomHeadline
                                    title={"Our Milestones"}
                                    textAlign={"center"}
                                    titleColor="#fff"
                              />
                              <CommonSwiper
                                    dynamicBreakpoints={dynamicBreakpoints}
                              >
                                    <Row className="g-0">
                                          {milestones?.map((milestone, i) => (
                                                <Col lg="3" key={i}>
                                                      <SwiperSlide>
                                                            <SingleMilestone>
                                                                  <h3>
                                                                        {
                                                                              milestone.year
                                                                        }
                                                                  </h3>
                                                                  <p>
                                                                        {
                                                                              milestone.title
                                                                        }
                                                                  </p>
                                                            </SingleMilestone>
                                                      </SwiperSlide>
                                                </Col>
                                          ))}
                                    </Row>
                              </CommonSwiper>
                        </Container>
                  </AboutMilestoneBox>
            </>
      );
};

export default AboutMilestone;
