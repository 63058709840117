import styled from "styled-components";

export const ContainerTimeline = styled.div`
      position: absolute;
      right: 1px;
      bottom: -50px;
      margin: auto;
      width: 100%;
      z-index: 2;
      text-align: center;
`;

export const HistoryTitle = styled.div`
      bottom: 20px;
      text-align: left;
      padding-left: 20px;
      position: absolute;
      font-size: 20px;
`;

export const SwiperContainerWrapper = styled.div`
      .swiper-slide {
            display: flex;
            background: #fff;
            min-height: 300px;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
      }

      .title {
            font-size: 18px;
            opacity: 0;
            transition: opacity 0.5s ease 0.5s;
      }

      .swiper-slide-active .title {
            opacity: 1;
      }

      .swiper-pagination-progressbar {
            position: relative;
            margin-bottom: 70px;
            background-color: transparent;
            height: 4px;
            border-bottom: 1px solid var(--secondary-color);
            width: 80%;
      }

      .swiper-pagination-progressbar-fill {
            background-color: var(--primary-color);
            height: 3px;
            top: 2px;
      }

      .swiper-pagination-custom {
            position: relative;
            list-style: none;
            margin: 1rem 0;
            padding: 0;
            display: flex;
            line-height: 1.66;
            bottom: 0;
            z-index: 11;
            width: 75%;
            display: flex;
      }

      .swiper-pagination-switch {
            position: relative;
            width: 100%;
            height: 30px;
            line-height: 30px;
            display: block;
      }

      .switch-title {
            position: absolute;
            font-weight: 400;
            font-size: 18px;
            right: 0;
            transform: translateX(50%);
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            z-index: 1;
      }

      .switch-title::after {
            position: absolute;
            top: calc(100% + 19px);
            right: 50%;
            transform: translateX(50%) translateY(-50%);
            width: 12px;
            height: 12px;
            background-color: #fff;
            border: 3px solid var(--primary-color);
            border-radius: 2rem;
            content: "";
            transition: all 0.2s ease-in-out;
      }

      .swiper-pagination-switch.active .switch-title::after {
            background-color: #fff;
            border: 3px solid var(--primary-color);
            width: 20px;
            height: 20px;
      }

      .swiper-pagination-switch.active
            ~ .swiper-pagination-switch
            .switch-title {
            color: var(--secondary-color);
            font-weight: 16px;
      }

      .swiper-pagination-switch.active
            ~ .swiper-pagination-switch
            .switch-title::after {
            background: var(--secondary-color);
      }

      @media screen and (max-width: 568px) {
            .swiper-pagination-custom {
                  margin: 1rem 0;
                  width: 100%;
                  margin-left: -1rem;
            }
            .swiper-pagination-progressbar {
                  width: 100%;
            }
      }
`;
