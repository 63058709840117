import styled from "styled-components";

export const BlogBoxDate = styled.div``;
export const BlogBoxImage = styled.div`
      img {
            border-radius: 15px;
      }

      @media screen and (max-width: 568px) {
            a {
                  width: 100%;

                  img {
                        width: 100%;
                  }
            }
      }
`;
export const BlogBoxContent = styled.div``;
export const BlogBoxTitle = styled.h4`
      a {
            color: #05152d;
            font-size: 18px;
            transition: all 0.3s ease-in-out;
            background-repeat: no-repeat;
            display: inline;
            position: relative;
            background-image: linear-gradient(
                  transparent calc(100% - 1px),
                  currentColor 1px
            );
            transition: 0.9s cubic-bezier(0.32, 0.32, 0.15, 1.17);
            background-size: 0 100%;
      }
`;
export const BlogBoxButton = styled.div``;
export const BlogBoxMeta = styled.ul``;
export const BlogBoxMetaItem = styled.li`
      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
      }
      span {
            font-size: 17px;
      }
`;
export const BlogBoxItem = styled.div`
      &:hover {
            ${BlogBoxTitle} {
                  a {
                        color: var(--primary-color);
                        background-size: 100% 100%;
                        transition: all 0.3s ease-in-out;
                        cursor: pointer;
                  }
            }
      }
`;
