import React, { useEffect, useState } from "react";
import {
      HomeProductContainer,
      PopularDestinationImage,
      PopularDestinationImageContent,
      PopularDestinationImageTitle,
} from "./styles";
import { Col, Container, Row } from "react-bootstrap";

import CustomHeadline from "../../../../CustomHeadline";
import { Link } from "react-router-dom";
import axios from "axios";
const HomeProducts = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/home/categories`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <HomeProductContainer>
                        <Container>
                              <CustomHeadline
                                    title="Plumber"
                                    subtitle={"Product category"}
                                    textAlign={"center"}
                              />
                              <Row className="g-4 mt-3">
                                    {/* <Col lg={5} className="">
							<h4>Our Categories</h4>
							{!loading
								? destinations?.length > 0 && (
										<PopularDestinationSlider
											onSwiper={setThumbsSwiper}
											spaceBetween={20}
											slidesPerView={5}
											freeMode={true}
											watchSlidesProgress={true}
											modules={[FreeMode, Navigation, Thumbs]}
											className="mySwiper"
										>
											{destinations?.map((destination, index) => (
												<SwiperSlide key={index}>
													<PopularDestinationContent>
														<PopularDestinationTitle>
															{destination.title}
														</PopularDestinationTitle>
													</PopularDestinationContent>
												</SwiperSlide>
											))}
										</PopularDestinationSlider>
								  )
								: null}
						</Col> */}
                                    {!loading
                                          ? categories?.map(
                                                  (destination, index) => (
                                                        <Col lg={4} key={index}>
                                                              <PopularDestinationImage>
                                                                    <img
                                                                          src={
                                                                                destination?.image
                                                                          }
                                                                          className="img-fluid"
                                                                          alt={
                                                                                destination?.title
                                                                          }
                                                                    />

                                                                    {/* Hover content */}
                                                                    <PopularDestinationImageContent>
                                                                          <PopularDestinationImageTitle>
                                                                                <Link
                                                                                      to={`/products/${destination?.slug}/category`}
                                                                                      style={{
                                                                                            color: "rgb(242, 242, 242)",
                                                                                            textDecoration:
                                                                                                  "none",
                                                                                      }}
                                                                                >
                                                                                      {
                                                                                            destination.title
                                                                                      }
                                                                                </Link>
                                                                          </PopularDestinationImageTitle>
                                                                    </PopularDestinationImageContent>
                                                              </PopularDestinationImage>
                                                        </Col>
                                                  )
                                            )
                                          : null}
                              </Row>
                        </Container>
                  </HomeProductContainer>
            </>
      );
};

export default HomeProducts;
