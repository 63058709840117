import Header from "../frontend/layout/header";
import MainRouter from "../Router";
import Footer from "../frontend/layout/footer";
import ScrollToTop from "../components/ScrollToTop";
import { CareerContextProvider } from "../Store/career-lists";
import { GlobalStyle } from "../styles/components/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/components/themes";
import { MetaContextProvider } from "../Store/MetaContext";
import { Toaster } from "react-hot-toast";

const App = () => {
      return (
            <>
                  <GlobalStyle />
                  <CareerContextProvider>
                        <MetaContextProvider>
                              <ThemeProvider theme={lightTheme}>
                                    <Toaster position="top-right" />
                                    <ScrollToTop />
                                    <Header />
                                    <MainRouter />
                                    <Footer />
                              </ThemeProvider>
                        </MetaContextProvider>
                  </CareerContextProvider>
            </>
      );
};

export default App;
