import React, { useContext } from "react";
import HomeSection from "./components/HomeSection";

import HomeAbout from "./components/HomeAbout";
import HomeHistory from "./components/HomeHistory";
import HomeBanner from "./components/HomeBanner";
import HomeTestomonials from "./components/HomeTestomonials";
import HomeProducts from "../../components/frontend/Home/components/HomeProducts";
import HomeFeatures from "../../components/frontend/Home/components/HomeFeatures";
import HomeBlogs from "../../components/frontend/Home/components/HomeBlogs";
import ValuesCertificates from "./components/ValuesSection";
import { MetaContext } from "../../Store";

const Home = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("home");

      return (
            <>
                  <HomeSection />
                  <ValuesCertificates />

                  {/* <HomeFeatures /> */}
                  {/* <HomeIntro /> */}
                  <HomeProducts />
                  {/* <HomeCategory /> */}
                  <HomeAbout />
                  {/* <CompanyInfo /> */}
                  <HomeFeatures />
                  {/* <HomeKeyFeatures/> */}
                  <HomeHistory />
                  <HomeBanner />
                  <HomeTestomonials />
                  <HomeBlogs />
            </>
      );
};

export default Home;
