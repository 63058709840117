import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HomePostCard = styled.div`
      display: flex;
      width: 100%;
      gap: 1rem;

      &:last-child {
            margin-right: 1.5rem;
      }
`;
export const HomePostImage = styled.div`
      width: 182px;
      padding: 0px 0px 10px 0px;

      img {
            border-radius: 15px;
            height: 170px;
            width: 100%;
            object-fit: cover;
      }
`;
export const HomePostTitle = styled.h4`
      text-wrap: no-wrap;
      font-size: 16px;
      text-align: justify;
      text-transform: capitalize;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      line-height: 1.2;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
`;
export const HomePostContent = styled.div`
      width: 237px;
`;
export const HomePostSource = styled.div`
      text-transform: capitalize;
      font-size: 14px;
`;
export const HomePostMeta = styled.div`
      margin-bottom: 12px;
      color: #555;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
`;
export const HomePostMetaItem = styled.div`
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .material-symbols-outlined {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
            font-size: 17px;
      }
      i {
            font-size: 18px;
      }
`;
export const HomePostButton = styled(Link)`
      font-size: 13px;
      border-radius: 5px;
      border: 1px solid ${(props) => props.theme.primary};
      padding: 6px 10px;
      margin-top: 7px;
      color: ${(props) => props.theme.primary};
      transition: all 0.3s ease-in;

      &:hover {
            background: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
            transition: all 0.3s ease-in;
      }
`;
