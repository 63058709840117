import React, { useContext } from "react";
import AboutFactoryNetwork from "../About/components/AboutFactoryNetwork";
import { SectionPadding } from "../../components/SectionPadding/styles";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import DealerBranch from "./Branch";
import { MetaContext } from "../../Store";

const DealerLocationPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("dealer");

      return (
            <>
                  <CustomBreadcrumb
                        subtitle={"Dealer Location"}
                        title={"Dealer Location"}
                  />
                  <SectionPadding>
                        <AboutFactoryNetwork />
                        <DealerBranch />
                  </SectionPadding>
            </>
      );
};

export default DealerLocationPage;
