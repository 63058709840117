import axios from "axios";
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

const DealerForm = () => {
      const [loading, setLoading] = useState(false);
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [address, setAddress] = useState("");
      const [message, setMessage] = useState("");

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  company_name: companyName,
                  address: address,
                  message: message,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dealer_enquiry`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setName("");
                              setEmail("");
                              setPhoneNumber("");
                              setCompanyName("");
                              setAddress("");
                              setMessage("");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.company_name) {
                                    toast.error(
                                          response.data.message.company_name
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <div className="dealer__form mb-5">
                  <Container>
                        <Row className="justify-content-center">
                              <Col md={12} className="justify-content-center">
                                    <h2>Dealer Enquiry Form</h2>
                                    <Form onSubmit={handleForm}>
                                          <Row className="g-4">
                                                <Col md={6}>
                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Full Name{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  required
                                                                  value={name}
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                      <Form.Group>
                                                            <Form.Label>
                                                                  Email Address{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="email"
                                                                  required
                                                                  value={email}
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                      <Form.Group controlId="phone">
                                                            <Form.Label>
                                                                  Phone Number{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="tel"
                                                                  required
                                                                  value={
                                                                        phoneNumber
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPhoneNumber(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                      <Form.Group controlId="email">
                                                            <Form.Label>
                                                                  Company Name{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  required
                                                                  value={
                                                                        companyName
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setCompanyName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                      <Form.Group controlId="address">
                                                            <Form.Label>
                                                                  Address{" "}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  required
                                                                  value={
                                                                        address
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setAddress(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                      <Form.Group
                                                            className="mb-3"
                                                            controlId="remarks"
                                                      >
                                                            <Form.Label>
                                                                  Remarks
                                                            </Form.Label>
                                                            <Form.Control
                                                                  as="textarea"
                                                                  rows={3}
                                                                  value={
                                                                        message
                                                                  }
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setMessage(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </Form.Group>
                                                </Col>
                                          </Row>

                                          <Button type="submit" className="btn">
                                                {loading ? (
                                                      <Spinner />
                                                ) : (
                                                      "Submit"
                                                )}
                                          </Button>
                                    </Form>
                              </Col>
                        </Row>
                  </Container>
            </div>
      );
};

export default DealerForm;
