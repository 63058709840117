import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const AboutFactoryNetworkRow = styled(Row)`
      h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
            margin: 10px 0px;
            color: var(--gray-color);
      }

      @media screen and (max-width: 568px) {
            > .col-lg-6 {
                  &:first-child {
                        order: 2;
                  }
                  &:last-child {
                        order: 1;
                  }
            }
      }
`;

export const AboutSingleFactoryCol = styled(Col)`
      height: 100%;
`;

export const MapImage = styled.div`
      position: relative;
      img {
            position: relative;
      }
`;
export const AllHeads = styled.span`
      opacity: ${(props) => (props.opacity ? 0 : 1)};
      transition: opacity 0.2s ease-in-out;
`;
export const AllManu = styled.span`
      opacity: ${(props) => (props.opacity ? 0 : 1)};
      transition: opacity 0.2s ease-in-out;
`;
export const HeadOffice = styled.span`
      position: absolute;
      left: ${(props) => (props.left ? props.left : "278px")};
      top: ${(props) => (props.top ? props.top : "157px")};
      background-image: url(/about/map1.png);
      background-size: 34px;
      background-repeat: no-repeat;
      padding: 18px 0px 18px 37px;
`;
export const ManuOffice = styled.span`
      position: absolute;
      left: ${(props) => (props.left ? props.left : "357px")};
      top: ${(props) => (props.top ? props.top : "157px")};
      background-image: url(/about/map2.png);
      background-size: 34px;
      background-repeat: no-repeat;
      padding: 18px 0px 18px 37px;
`;
export const HeadOfficeMain = styled.a`
      background-image: url("/about/brand.png");
      background-repeat: no-repeat;
      background-position: left center;
      display: inline-block;
      width: 100%;
      padding: 15px 0px 17px 70px;
      color: var(--gray-color);
`;

export const ManuOfficeMain = styled.a`
      background-image: url("/about/manu.png");
      background-repeat: no-repeat;
      background-position: left center;
      display: inline-block;
      width: 100%;
      padding: 15px 0px 17px 70px;
      color: var(--gray-color);
`;
