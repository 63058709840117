import React, { useState } from "react";
import {
      Modal,
      ModalHeader,
      ModalBody,
      ModalFooter,
      Col,
      Form,
} from "react-bootstrap";
import FormInput from "../FormInput";
import CommonButton from "../CommonButton";
import { CustomModal, ModalRow } from "./style";
import axios from "axios";
import toast from "react-hot-toast";

const EnquiryNow = ({ show, handleClose }) => {
      const [loading, setLoading] = useState(false);

      const [values, setValues] = useState({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            message: "",
      });
      const inputs = [
            {
                  id: 1,
                  name: "first_name",
                  type: "text",
                  placeholder: "First Name",
            },
            {
                  id: 2,
                  name: "last_name",
                  type: "text",
                  placeholder: "Last Name",
            },
            {
                  id: 3,
                  name: "email",
                  type: "email",
                  placeholder: "Email",
            },
            {
                  id: 4,
                  name: "phone_number",
                  type: "text",
                  placeholder: "Phone Number",
            },
            {
                  id: 5,
                  name: "message",
                  type: "textarea",
                  placeholder: "Your Message here....",
            },
      ];
      const onChange = (e) => {
            setValues({ ...values, [e.target.name]: e.target.value });
      };

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        values,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setValues({
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    phone_number: "",
                                    message: "",
                              });
                              setTimeout(() => {
                                    handleClose();
                              }, 1000);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <CustomModal
                        size="lg"
                        centered
                        show={show}
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>
                              <Modal.Title id="contained-modal-title-vcenter">
                                    Enquiry Now
                              </Modal.Title>
                        </ModalHeader>
                        <ModalBody>
                              <Form>
                                    <ModalRow>
                                          {inputs.map((input) => (
                                                <Col
                                                      lg={
                                                            input.type ===
                                                            "textarea"
                                                                  ? "12"
                                                                  : "6"
                                                      }
                                                      className="mb-3"
                                                >
                                                      <FormInput
                                                            key={input.id}
                                                            {...input}
                                                            type={input.type}
                                                            value={
                                                                  values[
                                                                        input
                                                                              .name
                                                                  ]
                                                            }
                                                            onChange={onChange}
                                                      />
                                                </Col>
                                          ))}
                                          <Col
                                                lg="12"
                                                className="d-flex justify-content-center align-items-center"
                                          >
                                                <CommonButton
                                                      title="Submit"
                                                      type="submit"
                                                      variant
                                                      onClick={handleForm}
                                                      loading={loading}
                                                />
                                          </Col>
                                    </ModalRow>
                              </Form>
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                  </CustomModal>
            </>
      );
};

export default EnquiryNow;
