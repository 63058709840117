import React, { useState } from "react";
import { Button, ButtonGroup, Card, Spinner } from "react-bootstrap";
import { FaFacebook, FaGoogle, FaTwitter, FaLinkedin } from "react-icons/fa";
import { ProductDetailQuality } from "./style";
import ProductEnquiry from "./ProductEnquiry";
import CommonButton from "../../../components/CommonButton";

function ProductDetailContent({ product }) {
      const [show, setShow] = useState(false);
      const [loading, setLoading] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => {
            setLoading(true);
            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 2500);
      };

      return (
            <>
                  <div className="">
                        <Card className="product-details-card p-3">
                              <Card.Body>
                                    <Card.Title as="h4">
                                          {product?.title}
                                    </Card.Title>
                                    {/* <div className="price">
                                          {product?.sale_price !== 0 ? (
                                                <>
                                                      <span className="current-price">
                                                            NRs.{" "}
                                                            {
                                                                  product?.sale_price
                                                            }
                                                      </span>
                                                      <span className="old-price">
                                                            NRs.{" "}
                                                            {product?.price}
                                                      </span>
                                                </>
                                          ) : (
                                                <>
                                                      <span className="current-price">
                                                            NRs.{" "}
                                                            {product?.price}
                                                      </span>
                                                </>
                                          )}
                                    </div> */}
                                    <hr />
                                    <Card.Text>
                                          <strong>Category:</strong>{" "}
                                          {product?.category}
                                    </Card.Text>
                                    <ProductDetailQuality className="product__detail--quantity mb-3">
                                          <CommonButton
                                                title="Enquiry Now"
                                                loading={loading}
                                                classes="mt-2"
                                                onClick={handleShow}
                                          />
                                    </ProductDetailQuality>

                                    <div className="post-share mt-4">
                                          <h5 className="heading-5">
                                                Share to:
                                          </h5>
                                          <ButtonGroup className="social-share">
                                                <Button
                                                      variant="link"
                                                      href={`https://www.facebook.com/sharer.php?t=${product?.title}&u=${window.location.href}`}
                                                      target="_blank"
                                                >
                                                      <FaFacebook size={22} />
                                                </Button>
                                                <Button
                                                      variant="link"
                                                      href={`https://x.com/intent/post?text=${product?.title}&url=${window.location.href}`}
                                                      target="_blank"
                                                >
                                                      <FaTwitter size={22} />
                                                </Button>
                                                <Button
                                                      variant="link"
                                                      href={`https://www.linkedin.com/shareArticle?title=${product?.title}&url=${window.location.href}`}
                                                      target="_blank"
                                                >
                                                      <FaLinkedin size={22} />
                                                </Button>
                                          </ButtonGroup>
                                    </div>
                              </Card.Body>
                        </Card>
                  </div>
                  <ProductEnquiry
                        slug={product?.slug}
                        show={show}
                        handleClose={handleClose}
                  />
            </>
      );
}

export default ProductDetailContent;
