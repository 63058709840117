import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GalleryHover, SingleGallery } from "../../styles";
import Fancybox from "../../../../components/FancyBox";

const VideoGallery = ({ videos }) => {
      return (
            <>
                  <Container className="mt-5">
                        <Row>
                              {videos.map((v, i) => (
                                    <Col lg="4" key={i}>
                                          <SingleGallery>
                                                <Fancybox>
                                                      <a
                                                            href={v?.video_link}
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={v?.image}
                                                                  alt=""
                                                            />
                                                            <GalleryHover className="d-flex align-items-center">
                                                                  <div className="">
                                                                        <span className="material-symbols-outlined text-center fs-1">
                                                                              play_circle
                                                                        </span>
                                                                  </div>
                                                                  <h5>
                                                                        {
                                                                              v?.title
                                                                        }
                                                                  </h5>
                                                            </GalleryHover>
                                                      </a>
                                                </Fancybox>
                                          </SingleGallery>
                                    </Col>
                              ))}
                        </Row>
                  </Container>
            </>
      );
};

export default VideoGallery;
