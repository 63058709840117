import React from "react";
import {
      HomePostButton,
      HomePostCard,
      HomePostContent,
      HomePostImage,
      HomePostMeta,
      HomePostMetaItem,
      HomePostSource,
      HomePostTitle,
} from "./styles";
import { Col } from "react-bootstrap";
import CommonButton from "../../../../CommonButton";

const HomePost = ({ data }) => {
      return (
            <>
                  <HomePostCard>
                        <HomePostImage>
                              <img
                                    src={data.image}
                                    alt={data.title}
                                    className="img-fluid"
                              />
                        </HomePostImage>
                        <HomePostContent>
                              <HomePostSource className="mb-2">
                                    {data.source}
                              </HomePostSource>

                              <HomePostTitle>{data.title}</HomePostTitle>
                              <HomePostMeta>
                                    <HomePostMetaItem>
                                          <span className="material-symbols-outlined">
                                                favorite
                                          </span>
                                          {data.likes}
                                    </HomePostMetaItem>
                                    <HomePostMetaItem>
                                          <span className="material-symbols-outlined">
                                                calendar_month
                                          </span>
                                          {data.date}
                                    </HomePostMetaItem>
                              </HomePostMeta>
                              {/* <CommonButton to={data.slug} title="Know More" /> */}
                        </HomePostContent>
                  </HomePostCard>
            </>
      );
};

export default HomePost;
