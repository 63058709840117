import { Row } from "react-bootstrap";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const productdetailsimages = [
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
      "/images/cpvc_pipes.png",
];

export const ThumbSwiper = styled(Swiper)`
      .swiper-slide {
            padding: 10px;

            img {
                  object-fit: contain !important;
            }
      }
`;

export const TabContent = styled(Row)`
      .tab-content {
            min-height: 120px;
      }
`;
