import styled from "styled-components";

export const ApplicationImage = styled.div`
	img {
		width: 70px;
		height: 70px;
	}
`;
export const ApplicationBox = styled.div`
	width: 100%;
	height: 100%;
	h6 {
		margin-top: 15px;
		text-align: center;
		font-size: 18px;
	}
`;

export const ProductTabTitle = styled.h4``;
