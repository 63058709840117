import { Row } from "react-bootstrap";
import styled from "styled-components";

export const AdvantagesBox = styled(Row)`
	p {
		font-size: 18px;
		color: #fff;
		text-align: justify;
	}
`;
export const AdvantagesImage = styled.div`
	min-width: 80px;
	max-width: 80px;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #fff;
	span {
		text-align: center;
		font-size: 14px;
	}
	img {
		width: 40px;
		height: 40px;
	}
`;

export const AdvantageRow = styled(Row)`
	position: relative;
`;

export const AdvantageBg = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/advantages/pipe1.png");
    background-size: 600px;
    background-position: center 50%;
    background-repeat: no-repeat;
    transform: rotate(90deg);
    z-index: -1;
  }
`;

export const ProductAdvantagesImage = styled.div`
	height: 100%;
	img {
		height: 100%;
	}
`;
