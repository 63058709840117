import React, { useState } from "react";
import {
      AboutFactoryNetworkRow,
      AboutSingleFactoryCol,
      HeadOfficeMain,
      ManuOfficeMain,
      MapImage,
} from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import CustomHeadline from "../../../../components/CustomHeadline";
import CommonButton from "../../../../components/CommonButton";

const AboutFactoryNetwork = () => {
      const [showAllHeads, setShowAllHeads] = useState(false);
      const [showAllManu, setShowAllManu] = useState(false);

      return (
            <>
                  <div className="sectionMargin">
                        <Container>
                              <AboutFactoryNetworkRow>
                                    <Col lg="6">
                                          <CustomHeadline title="Strategic Distribution & Factory Network" />
                                          <h3>
                                                Pan Nepal distribution network
                                                our biggest strength – through
                                                1500 ++ Channel Partners.
                                          </h3>
                                          <Row>
                                                <AboutSingleFactoryCol lg="6">
                                                      <HeadOfficeMain
                                                            role="button"
                                                            onMouseEnter={() =>
                                                                  setShowAllManu(
                                                                        true
                                                                  )
                                                            }
                                                            onMouseLeave={() =>
                                                                  setShowAllManu(
                                                                        false
                                                                  )
                                                            }
                                                      >
                                                            Head Office
                                                      </HeadOfficeMain>
                                                </AboutSingleFactoryCol>
                                                <AboutSingleFactoryCol>
                                                      <ManuOfficeMain
                                                            role="button"
                                                            onMouseEnter={() =>
                                                                  setShowAllHeads(
                                                                        true
                                                                  )
                                                            }
                                                            onMouseLeave={() =>
                                                                  setShowAllHeads(
                                                                        false
                                                                  )
                                                            }
                                                      >
                                                            Manufacturing Office
                                                      </ManuOfficeMain>
                                                </AboutSingleFactoryCol>

                                                <div>
                                                      <CommonButton
                                                            title={
                                                                  "Dealer Enquiry"
                                                            }
                                                            slug={
                                                                  "/dealer-enquiry"
                                                            }
                                                            classes="mt-4"
                                                      />
                                                </div>
                                          </Row>
                                    </Col>
                                    <Col lg="6">
                                          <MapImage>
                                                <img
                                                      src="/about/nepalmap2.png"
                                                      className="img-fluid"
                                                      alt=""
                                                />
                                          </MapImage>
                                    </Col>
                              </AboutFactoryNetworkRow>
                        </Container>
                  </div>
            </>
      );
};

export default AboutFactoryNetwork;
