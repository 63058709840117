import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import CustomHeadline from "../../components/CustomHeadline";
import BlogList from "./components/BlogList";
import axios from "axios";
import { MetaContext } from "../../Store";

const BlogPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug("blogs");
      
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlogs(response.data.blogs);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <CustomBreadcrumb title="BLOG" subtitle={"BLOG"} />
                  <div className="sectionMargin marginBottom">
                        <CustomHeadline
                              subtitle={"Blogs & News"}
                              title="Latest  Posts"
                              textAlign={"center"}
                        />
                        <Container>
                              <BlogList
                                    loading={loading}
                                    blogs={blogs}
                                    categories={categories}
                              />
                        </Container>
                  </div>
                  {/* <CustomFooter/> */}
            </>
      );
};

export default BlogPage;
