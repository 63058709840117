import React, { useState } from "react";
import "../FAQ/style.css";
import CommonButton from "../../../../components/CommonButton";
import EnquiryNow from "../../../../components/EnquiryNow";

const FaqSide = () => {
      const [show, setShow] = useState(false);
      const [loading, setLoading] = useState(false);

      const handleShow = () => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
                  setShow(true);
            }, 3000);
      };

      const handleClose = () => setShow(false);
      return (
            <div className="faq-side">
                  <div className="faq-content">
                        <h2>Need Any Consult ?</h2>
                        <p>
                              Find answers to frequently asked questions about
                              Bizipress, contacts, and general customer account
                              information.
                        </p>
                        <CommonButton
                              title={"Send Enquiry"}
                              onClick={handleShow}
                              loading={loading}
                        />
                        <EnquiryNow show={show} handleClose={handleClose} />
                  </div>
            </div>
      );
};

export default FaqSide;
