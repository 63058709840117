import React from 'react'
import { NavLink } from 'react-router-dom'

const SingleCareer = ({jobs}) => {
  return (
      <div className="single-career__widget">
     
        <li>
          <NavLink to={`/careers/${jobs.slug}`} className={'font-xs'}>{jobs.title}</NavLink>
        </li>
      
    </div>
  )
}

export default SingleCareer
